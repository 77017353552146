import {
  L_WIDTH,
  L_WIDTH_DOWN,
  M_WIDTH,
  M_WIDTH_DOWN,
  ML_WIDTH,
  ML_WIDTH_DOWN,
  S_WIDTH,
  S_WIDTH_DOWN,
  XS_WIDTH,
  XS_WIDTH_DOWN,
  XL_WIDTH,
  XL_WIDTH_DOWN,
  XXL_WIDTH,
  XXL_WIDTH_DOWN,
  XXXL_WIDTH,
  XXXL_WIDTH_DOWN,
} from 'src/modules/app/constants';

export const sizes = {
  xs2Device: `${XS_WIDTH_DOWN}px`,
  xs2DeviceUp: `${XS_WIDTH}px`,
  xsDevice: `${S_WIDTH_DOWN}px`,
  xsDeviceUp: `${S_WIDTH}px`,
  sDevice: `${M_WIDTH_DOWN}px`,
  sDeviceUp: `${M_WIDTH}px`,
  mDevice: `${ML_WIDTH_DOWN}px`,
  mDeviceUp: `${ML_WIDTH}px`,
  mlDevice: `${L_WIDTH_DOWN}px`,
  mlDeviceUp: `${L_WIDTH}px`,
  lDevice: `${XL_WIDTH_DOWN}px`,
  lDeviceUp: `${XL_WIDTH}px`,
  xlDevice: `${XXL_WIDTH_DOWN}px`,
  xlDeviceUp: `${XXL_WIDTH}px`,
  xl2Device: `${XXXL_WIDTH_DOWN}px`,
  xl2DeviceUp: `${XXXL_WIDTH}px`,
  xxlDevice: '1920px',
  xxlDeviceUp: '1921px',
};

const sizeMin = {
  xs2DeviceUp: XS_WIDTH,
  xsDeviceUp: S_WIDTH,
  sDeviceUp: M_WIDTH,
  mDeviceUp: ML_WIDTH,
  mlDeviceUp: L_WIDTH,
  lDeviceUp: XL_WIDTH,
  xlDeviceUp: XXL_WIDTH,
  xl2DeviceUp: XXXL_WIDTH,
  xxlDeviceUp: 1921,
};
export const deviceMin = {
  xs2DeviceUp: `(min-width: ${sizeMin.xs2DeviceUp})`,
  xsDeviceUp: `(min-width: ${sizeMin.xsDeviceUp})`,
  sDeviceUp: `(min-width: ${sizeMin.sDeviceUp})`,
  mDeviceUp: `(min-width: ${sizeMin.mDeviceUp})`,
  mlDeviceUp: `(min-width: ${sizeMin.mlDeviceUp})`,
  lDeviceUp: `(min-width: ${sizeMin.lDeviceUp})`,
  xlDeviceUp: `(min-width: ${sizeMin.xlDeviceUp})`,
  xl2DeviceUp: `(min-width: ${sizeMin.xl2DeviceUp})`,
  xxlDeviceUp: `(min-width: ${sizeMin.xxlDeviceUp})`,
};

const sizeMax = {
  xs2Device: XS_WIDTH_DOWN,
  xsDevice: S_WIDTH_DOWN,
  sDevice: M_WIDTH_DOWN,
  mDevice: ML_WIDTH_DOWN,
  mlDevice: L_WIDTH_DOWN,
  lDevice: XL_WIDTH_DOWN,
  xlDevice: XXL_WIDTH_DOWN,
  xl2Device: XXXL_WIDTH_DOWN,
  xxlDevice: 1920,
};
export const deviceMax = {
  xs2Device: `(max-width: ${sizeMax.xs2Device})`,
  xsDevice: `(max-width: ${sizeMax.xsDevice})`,
  sDevice: `(max-width: ${sizeMax.sDevice})`,
  mDevice: `(max-width: ${sizeMax.mDevice})`,
  mlDevice: `(max-width: ${sizeMax.mlDevice})`,
  lDevice: `(max-width: ${sizeMax.lDevice})`,
  xlDevice: `(max-width: ${sizeMax.xlDevice})`,
  xl2Device: `(max-width: ${sizeMax.xl2Device})`,
  xxlDevice: `(max-width: ${sizeMax.xxlDevice})`,
};