import { getFontPath, getFontUnicodeRange } from 'src/helpers/font';
import { css } from 'styled-components';
import { GlobalStyleProps } from '../types';

const fonts = css`

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-BlackItalic.woff2') format('woff2'),
    url('/fonts/Nunito-BlackItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-ExtraLightItalic.woff2') format('woff2'),
    url('/fonts/Nunito-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-ExtraBold.woff2') format('woff2'),
    url('/fonts/Nunito-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-SemiBold.woff2') format('woff2'),
    url('/fonts/Nunito-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-ExtraBoldItalic.woff2') format('woff2'),
    url('/fonts/Nunito-ExtraBoldItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-ExtraLight.woff2') format('woff2'),
    url('/fonts/Nunito-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Black.woff2') format('woff2'),
    url('/fonts/Nunito-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-BoldItalic.woff2') format('woff2'),
    url('/fonts/Nunito-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Nunito-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Regular.woff2') format('woff2'),
    url('/fonts/Nunito-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-LightItalic.woff2') format('woff2'),
    url('/fonts/Nunito-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Bold.woff2') format('woff2'),
    url('/fonts/Nunito-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Italic.woff2') format('woff2'),
    url('/fonts/Nunito-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito-Light.woff2') format('woff2'),
    url('/fonts/Nunito-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }


  @font-face {
    font-family: "HelveticaNeue";
    src: url("/fonts/HelveticaNeueLTProEx.otf");
    font-display: swap;
  }

`;

export default fonts;
