import buttonComponent from './components/buttonComponent';
import iconComponent from './components/iconComponent';
import inputComponent from './components/inputComponent';
import button from './elements/button';
import form from './elements/form';
import headings from './elements/headings';
import images from './elements/images';
import input from './elements/input';
import label from './elements/label';
import links from './elements/links';
import lists from './elements/lists';
import page from './elements/page';
import select from './elements/select';
import text from './elements/text';
import fonts from './generic/fonts';
import normalize from './generic/normalize';
import helpersButton from './helpers/button';
import helpersFlex from './helpers/flex';
import imagePlaceholder from './helpers/imagePlaceholder';
import helpersText from './helpers/text';
import block from './utilities/block';
import colors from './utilities/colors';
import cursor from './utilities/cursor';
import display from './utilities/display';
import flex from './utilities/flex';
import positions from './utilities/positions';
import spacing from './utilities/spacing';
import typography from './utilities/typography';
import ckEditor from './vendor/ckEditor';
import rcCollapse from './vendor/rcCollapse';
import { createGlobalStyle } from 'styled-components';
import { GlobalStyleProps } from './types';
import mobile from "./utilities/mobile";
import container from "./utilities/container";
import {sizes} from "../mediaQueries";

const Base = createGlobalStyle<GlobalStyleProps>`
  /**
   * 1. Generic
   * ==========
   * Ground-zero styles.
  **/

  ${normalize}
  ${fonts}
  ${container}
    /**
     * 2. Elements
     * ===========
     * Unclassed HTML elements (type selectors).
    **/
      /**  ${button}  **/
  ${form}
  ${headings}
  ${images}
  ${input}
  ${label}
  ${links}
  ${lists}
  ${page}
  ${select}
  ${text}
    /**
     * 3. Vendor Components
     * ====================
    **/
  ${rcCollapse}
  ${ckEditor}
    /**
     * 4. Helpers
     * ==========
     * General purpose helpers for common situations. Unlike utilities, helpers usually cover more CSS
     * properties than one. And unlike utilities, using \`!important\` is not allowed here.
    **/
  ${helpersButton}
  ${helpersFlex}
  ${imagePlaceholder}
  ${helpersText}
    /**
     * 5. Components
     * =============
     * Actual UI components.
    **/
  ${buttonComponent}
  ${iconComponent}
  ${inputComponent}
    /**
     * 6. Utilities
     * ============
     * High-specificity overrides. The only place where \`!important\` is allowed.
     * For generate all utilities classes please run \`yarn generate:css\`.
    **/
  ${block}
  ${colors}
  ${cursor}
  ${display}
  ${flex}
  ${positions}
  ${spacing}
  ${typography}
  ${mobile}
  input[type=text], input[type=password], input[type=email] {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.015em;
    color: #141126;
    background: #FFFFFF;
    border: 1px solid #BABCC4;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    padding: 0 1rem;

    &:focus {
      outline: none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000;
      font-weight: 500;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #dedede;
      font-weight: 300;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #dedede;
      font-weight: 300;
    }

  }

  input[type=checkbox], select {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.015em;
    color: #141126;
    background: #FFFFFF;
    border: 1px solid #BABCC4;
    box-sizing: border-box;
    border-radius: 4px;
    //height: 48px;
    height: 1.2rem;
    width: 1.2rem;


    &:focus {
      outline: none;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #dedede;
      font-weight: 300;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #dedede;
      font-weight: 300;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #dedede;
      font-weight: 300;
    }

  }

  .datePicker {
    z-index: 999;
    background-color: white;
    padding: 25px;
    margin-top: 22px;
    text-align: center;
    margin-left: -12px;
    width: 331px;
    border-radius: 4px;
    position: fixed;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    font-family: Nunito, serif;

    button {

      :disabled {
        color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
      }

      &:hover {
        background: transparent;
      }
    }

    .datePicker__button {
      font-size: 14px;
      font-weight: bold;
    }

    h5 {
      text-align: left;
      margin-left: 20px;
      margin-top: -10px;
      font-size: 15px;
    }


    &__back {
      cursor: pointer;

      &:after {
        left: -26px;
        display: block;
        background-image: url("/icons/Vector 1.svg");
        width: 11px;
        height: 7px;
        border: none;
        content: "";
        transform: rotate(90deg);
      }
    }


    &__timeBoxes {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      &__label {
        font-size: 14px;
        text-transform: capitalize;
        font-weight: bold;
      }

      &__innerBox {
        position: relative;

        &__arrow {
          cursor: pointer;

          &__Up {
            top: 0;
            display: block;
            position: absolute;
            width: 100%;
            height: 30px;
            cursor: pointer;


            &:after {
              left: -26px;
              display: block;
              background-image: url("/icons/Vector 1.svg");
              width: 11px;
              height: 7px;
              border: none;
              content: "";
              transform: rotate(180deg);
              margin-left: 49px;
              margin-top: 11px;
              scale: 2;
            }
          }

          &__Down {
            cursor: pointer;
            bottom: 0;
            display: block;
            position: absolute;
            width: 100%;
            height: 30px;
            cursor: pointer;

            &:after {
              display: block;
              background-image: url("/icons/Vector 1.svg");
              width: 11px;
              height: 7px;
              border: none;
              content: "";
              margin-left: 49px;
              margin-top: 11px;
              scale: 2;
            }
          }
        }

        &__value {
          -webkit-appearance: none;
          text-align: center;
          width: 110px;
          height: 140px;
          font-family: Nunito, serif;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          border: 1px solid #959ea9;
          border-radius: 5px;
        }

        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }


    button {
      background-color: transparent;
      margin-left: 0;
      border: none;

    }

    &__button {
      min-width: 90%;
      height: 40px;
      margin-top: 2em;
      //margin-left: 2.5%;
      color: white;
      font-size: 12px;
      background-color: #79D231 !important;
    }
  }

  .react-calendar {
    display: flex;
    flex-direction: column;

    ::first-letter {
      text-transform: uppercase;
    }

    &__month-view__days__day {
      border-radius: 50% !important;
    }

    &__tile {
      border-radius: 4px;
      height: 39px;
      font-size: 13px;
      font-family: Nunito, serif;

      &--now {
        border: 1.2px solid #959ea9 !important;
      }

      &--active {
        background-color: #79D231 !important;
        border: none !important;
        color: #fff !important;
        border-color: #79D231;
      }
    }


    &__navigation {
      display: flex;
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: bold;

      &__label {
        text-transform: capitalize;
        padding: 0 0 0 10px;

        span::before {
          content: none;

        }

        &__labelText {
          background-color: transparent;
          color: black;
          float: left;
        }
      }


      &__prev-button {
        order: 2;
        top: 32px;
        left: 283px;
        padding: 0 10px 0 10px;

        :first-letter {
          display: none;
        }

        svg {
          display: none;
        }
      }

      &__next-button {
        order: 3;
        top: 32px;
        right: 359px !important;
        padding: 0 5px 0 10px;

        :first-letter {
          display: none;
        }

        svg {
          display: none;
        }
      }

      &__prev2-button {
        display: none;
      }

      &__next2-button {
        display: none;
      }
    }

    &__month-view {

      &__weekdays {
        text-align: center;
        padding-bottom: 20px;
        font-weight: bold;

        &__weekday {
          abbr {
            text-decoration: none;
          }
        }

      }

      &__days {
        &__day {

          &--neighboringMonth {
            visibility: hidden;
          }
        }
      }
    }
  }

  @media (max-width: ${sizes.mDevice}) {
    .datePicker {
      position: fixed;
      top: 50% !important;
      left: 52% !important;
      transform: translate(-50%, -50%);

    }
  }

  .splide__list {
    li {
      display: inline-block;
    }
  }

  .splide__arrows--ltr {
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }

  .splide__arrow {
    position: relative !important;
    background: white !important;
    border-radius: 4px !important;
    font-size: 10px !important;
    color: black !important;
    opacity: 1 !important;
    width: 40px !important;
    height: 40px !important;
    margin: 0 9px !important;
    left: 0 !important;
    right: 0 !important;
    @media (max-width: ${sizes.mDevice}) {
      font-size: 7px !important;
      width: 30px !important;
      height: 30px !important;
    }
  }

  .modal {
    color: #000;
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    max-height: 100%;
    min-height: 100%;

    overflow-y: scroll;
    overflow-x: hidden;
    outline: 0;
    z-index: 9999;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .modal-dialog {
    position: relative;
    width: 500px;
    margin: auto;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height:100vh ;
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media (max-width: ${sizes.mDevice}) {
      width: 100%;
    }

  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #000;
    pointer-events: auto;
    background-color: #FFFFFF;
    background-clip: padding-box;
    //border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: 0.5rem;
    outline: 0;
  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 2rem;
    border-bottom: 1px solid #dedede;
    justify-content: space-between;

    .btn-close {
      box-sizing: content-box;
      width: 1em;
      height: 1em;
      padding: .25em .25em;
      border: 0;
      border-radius: .25rem;
      color: #575656;
      background: transparent;

      &:before {
        display: inline-block;
        content: "x";
        font-size: 20px;
        font-weight: bold;
        border-bottom: 0;
        position: relative;
        margin-right: 0.5rem;
        top: -1rem;
      }
    }
  }

  .modal-body {
    padding: 2rem;
  }

  .modal .modal-header .title {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #141126
  }

  .modal-footer {
    border-top: 1px solid #dedede;
    justify-content: space-between;
  }

  .text-danger {
    color: rgba(220, 53, 69) !important;
  }

  .checkbox {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    cursor: pointer;

    .bar {
      width: 22px;
      height: 22px;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      top: 6px;
      left: -4px;
      display: inline-block;
      margin-right: .5rem;
      cursor: pointer;
      background: #ffffff;

      &.checked {
        background: #ffffff url("/icons/checked_green.svg") no-repeat;
        background-position-x: 4px;
        background-position-y: 5px;
      }
    }
  }

  .radiobox {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    cursor: pointer;

    .bar {
      width: 22px;
      height: 22px;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      position: relative;
      top: 6px;
      left: -4px;
      display: inline-block;
      margin-right: .5rem;
      cursor: pointer;
      border-radius: 50px;

      &.checked {
        &::after {
          content: "";
          background: linear-gradient(0deg, #79D231, #79D231), linear-gradient(63.45deg, #A8D91C -1.43%, #2DC753 94.42%);
          position: absolute;
          height: 20px;
          width: 20px;
          border: 5px solid #fff;
          border-radius: 50px
        }
      }
    }
  }


  .dropdown-control {
    position: relative;
    border: 1px solid #dedede;
    border-radius: 4px;
    //margin: 0 0.2rem;
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: normal;
    background: #fff;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #141126;
    height: 48px;
    padding-left: 1.5rem;
    user-select: none;

    &::after {
      content: "";
      width: 14px;
      height: 9.5px;
      right: 2rem;
      margin: 0;
      position: absolute;
      background-image: url("/icons/Vector 1.svg");
      background-size: contain;
      background-repeat: no-repeat;
      /* transform: rotate(-90deg); */
      transition-property: transform;
      transition-timing-function: ease-out, ease-in, linear, ease-in-out;
      transition-duration: 0.5s;
      bottom: 1.8rem;
    }

    .value {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      overflow: hidden;
      width: 75%;
      white-space: nowrap;
    }

    .dropdown {
      background-color: #fff;
      width: 101%;
      left: -1px;
      padding: 0.5rem 1rem;
      z-index: 100;
      position: absolute;
      top: 48px;
      border-radius: 0 0 5px 5px;
      border: 1px solid #dedede;
      display: none;

      &.show {
        display: block;
      }

      ul {
        padding: 0;

        li {
          list-style: none;
          text-decoration: underline;
        }
      }

      @media (max-width: ${sizes.mDevice}) {
        width: 100%;
        position: absolute;
        left: 0;
        margin-top: 0.6rem;
      }
    }
  }

  .form-control, textarea {
    border: 1px solid #dedede;
    border-radius: 4px;
    //margin: 0 0.2rem;
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: normal;
    background: #fff;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.015em;
    color: #141126;
    height: 48px;
    padding-left: 1.5rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .form-control {
    &.is-invalid {
      border-color: #F72D01;
    }
  }

  textarea {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: initial;
    resize: none;

  }

  .btn-sportybe {
    background: linear-gradient(0deg, #79D231, #79D231),
    linear-gradient(63.45deg, #A8D91C -1.43%, #2DC753 94.42%);
    font-weight: 900;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 3px;
    padding: 1rem 10rem;

  }

  .btn-sportybe-black {
    background: black;
    font-weight: 900;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 3px;

    &:hover {
      background: black;
      color: #FFFFFF;

    }
  }

  .text-muted {
    color: rgba(33, 37, 41, .75);

  }

  .form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: rgba(33, 37, 41, .75);;
  }

  .pagination {
    text-align: center;
    display: block;
    position: relative;

    ul {
      display: block;
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;

      li {
        display: inline-block;
        padding: 0 0.5rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        text-decoration: none;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.015em;
        color: #141126;
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: normal;

        &.active {
          font-weight: normal;
        }
      }
    }

    .more {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.015em;
      color: #141126;
      font-family: Nunito, serif;
      font-style: normal;
      font-weight: normal;
    }
  }

  .horizontaly-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


`;

export default Base;
