import { sizes } from 'src/styles/mediaQueries';
import { css } from 'styled-components';

const ckEditor = css(
  ({ theme }) => css`
    .ckContent {
      img {
        height: auto;
        max-width: 100%;
        margin: 1em 0;
      }

      span.align-left {
        float: left;
      }

      span.align-right {
        float: right;
      }

      img.align-left,
      span.align-left img {
        float: left;
        margin-right: 15px;

        @media (max-width: ${sizes.sDevice}) {
          float: none;
          display: block;
          margin: 0 0 16px;
        }
      }

      img.align-right,
      span.align-right img {
        float: right;
        margin-left: 46px;
        margin-top: 0;
        margin-bottom: 16px;

        @media (max-width: ${sizes.sDevice}) {
          float: none;
          display: block;
          margin: 0 0 16px;
        }
      }

      .align-center {
        text-align: center;
      }

      .highlight {
        font-size: 14px;
        font-weight: bold;
        color: ${theme.colors.green[40]};
        background: none;
      }

      hr {
        height: 3px;
        border: 0;
        background: lightgray;
        margin: 25px 0;
      }

      ul,
      ol {
        line-height: 1.5;
        font-size: 16px;
        color: ${theme.colorTextPrimary};
        margin-left: 0;
        margin-bottom: 32px;

        @media (max-width: ${sizes.sDevice}) {
          padding-left: 16px;
        }
      }

      ul {
        li {
          list-style: none;
          position: relative;
          padding-left: 26px;
          padding-bottom: 16px;

          &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 8px;
            height: 8px;
            background: ${theme.colors.grey[30]};
            border-radius: 50%;
          }
        }
      }

      ol {
        list-style-type: decimal;
        margin-left: 13px;

        li {
          list-style: decimal;
          padding-left: 14px;
          padding-bottom: 22px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            border: 1px solid ${theme.borderColorPrimary};
            height: 32px;
            width: 32px;
            border-radius: 50%;
            left: -28px;
            top: -4px;
          }
        }
      }

      .clearfix {
        clear: both;
      }
    }
  `
);

export default ckEditor;
