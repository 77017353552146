import { css } from 'styled-components';

const helpersText = css`
  .truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export default helpersText;
