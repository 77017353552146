import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectCurrentPageType} from '../app/reducer/selectors';
import {useApplicationContext} from '../../common/applicationWrapper/useApplicationContext';
import {Cookie} from '../../types';
import useRouter from "../../routing/utils/useRouter";
import ReactGA from "react-ga4";
import {setAppMetaData} from "../app/actions";
import {useRouting} from "../../routing/utils/useRouting";

interface GlobalListenersProps {
}

const GlobalListeners: React.FC<GlobalListenersProps> = () => {
    const {router} = useRouting();
    const dispatch = useDispatch();

    const {
        value: {
            configuration: {releaseId},
        },
    } = useApplicationContext();

    usePageTypeChange();

    useEffect(() => {
        // HACK for next-translate to add v param into query while loading namespaces
        window.releaseId = releaseId;
        history.scrollRestoration = 'manual';
    }, []);

    useEffect(() => {

        if (document.cookie.match('(^|;)\\s*' + Cookie.JWT_COOKIE + '\\s*=\\s*([^;]+)')) {
            return;
        }

        window.location.reload();
    }, [])

    return null;
};

const usePageTypeChange = () => {
    const pageType = useSelector(selectCurrentPageType);
    const router = useRouter();

    useEffect(() => {
        ReactGA.send(router.asPath + window.location.search);
    }, [pageType]);
};
export default GlobalListeners;
