import {compile, match, pathToRegexp} from 'path-to-regexp';
import {ParsedUrlQuery} from 'querystring';
import {parse} from 'url';
import {setUrlParams} from './setUrlParams';
import {NamedRoute, Route, RouteDef, RouteName, RoutesEnum, UrlFunctionParams} from '../types';
import {NextPageContext} from "next";
import {useConfiguration} from "../../configuration/useConfiguration";
import {ApplicationContextType} from "../../common/applicationWrapper/applicationContext";
import {useApplicationContext} from "../../common/applicationWrapper/useApplicationContext";

interface IncompleteRoute extends Omit<Route, 'url'> {
  url?: Route['url'];
}

type IncompleteRoutes = Record<keyof typeof RouteName, IncompleteRoute>;

export const addUrlFunction = (applicationContext: ApplicationContextType['value'], defaultRoutes?: IncompleteRoutes): RoutesEnum => {
  return Object.entries(defaultRoutes || {}).reduce((acc, [name, def]) => {
    const {pattern, ...restDef} = def;
    return {...acc, [name]: routeWithContext(pattern, restDef, applicationContext)};
  }, defaultRoutes as RoutesEnum);
};

export const loadRoutes = async () => {
  const getRoutes = () => import('../routes') as unknown as (() => Promise<{ default: RoutesEnum }>) | undefined;

  if (!getRoutes) {
    throw new Error('No routes defined ');
  }

  // eslint-disable-next-line @typescript-eslint/await-thenable
  const moduleResult = await getRoutes();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return moduleResult.default;
};

export const createUrlFromPattern = (pattern: string, query: UrlFunctionParams = {}): string => {
  const getUrl = compile(pattern);

  // console.log(query);

  const url = getUrl(query) || '/';

  // get unused params
  const usedParams = parseRouteParams({pattern}, url);
  const paramsLeft = Object.keys(query).reduce((acc, paramKey) => {
    return usedParams[paramKey] != undefined ? acc : {...acc, [paramKey]: query[paramKey] as any};
  }, {} as ParsedUrlQuery);

  // and set them as search query string into url
  return setUrlParams(url, paramsLeft as any);
};

export const routeWithContext = (pattern: string, def: RouteDef = {}, applicationContext?: ApplicationContextType['value'] | undefined): Route => {

  const splitted: string[] | undefined = applicationContext?.host.split('.');
  const subdomain = splitted?.length == 3 ? splitted[0] : null;

  if (subdomain != null && def.enableSubdomain) {
    const protocol = applicationContext?.secure ? 'https://' : 'http://';

    return {
      ...def,
      pattern,
      url: (query) => createUrlFromPattern(pattern, query),
    };
  }

  const protocol = applicationContext?.configuration.environment != 'DEVELOPMENT' ? 'https://' : 'http://';
  return {
    ...def,
    pattern,
    url: (query) => protocol + applicationContext?.configuration.domain + createUrlFromPattern(pattern, query),
  };
};

export const route = (pattern: string, def: RouteDef = {}): Route => {
  return {
    ...def,
    pattern,
    url: (query) => createUrlFromPattern(pattern, query),
  };
};

export const removeLanguageFromUrl = (pathname: string | null | undefined): string => {
  const found = pathname?.match(/^\/[a-z]{2}-[A-Z]{2}\//);

  if (found) {
    const lngPrefix = found[0];
    pathname = pathname?.replace(lngPrefix, '/') || null;
  }

  return pathname || '';
};

export const getRouteByUrl = (routesParam: RoutesEnum, url: string): NamedRoute | null => {
  let {pathname} = parse(url, true);

  pathname = removeLanguageFromUrl(pathname);

  const {links, ...routes} = routesParam; // eslint-disable-line
  const foundEntry = pathname && Object.entries(routes).find(([, r]) => pathToRegexp(r.pattern).exec(pathname!));

  if (!foundEntry) {
    return null;
  }

  return {
    name: foundEntry[0],
    ...foundEntry[1],
  };
};

export const parseRouteParams = <R extends { pattern: string } = Route>(r: R, url: string): ParsedUrlQuery => {
  const matchFn = match(r.pattern);
  const parsed = matchFn(url);
  return parsed ? (parsed.params as ParsedUrlQuery) : {};
};

export const convertUrlToNextUrl = (routes: RoutesEnum, url: string): string => {
  const parsedUrl = parse(url, true);
  const pathname = removeLanguageFromUrl(parsedUrl.pathname);

  if (pathname === '/') {
    return url;
  }

  const r = pathname && getRouteByUrl(routes, pathname);

  if (!r || !pathname) {
    return url;
  }

  const {query} = parsedUrl;
  const params = parseRouteParams(r, pathname);
  const q = query && typeof query === 'object' ? query : ({} as ParsedUrlQuery);

  return setUrlParams(r.page || '/' + r.name, {...params, ...q});
};
