import {PrefetchOptions} from 'next/dist/shared/lib/router/router';
import {useRouter as useNextRouter} from 'next/router';
import {convertUrlToNextUrl} from './index';
import i18nConfig from '../../../i18n';
import {useConfiguration} from '../../configuration/useConfiguration';
import {getDefaultLocale, getUrlWithLanguagePrefix} from '../../lib/i18n/utils';
import {TransitionOptions} from '../types';
import useRoutes from './useRoutes';

const useRouter = () => {
  const router = useNextRouter();
  const routes = useRoutes();
  const {domain} = useConfiguration();

  const getNewParameters = <T>(url: any, as?: string, options?: T): [string, string | undefined, T | undefined] => {
    const newUrl = convertUrlToNextUrl(routes, url);
    const newAs = as || getUrlWithLanguagePrefix(url, domain, router.locale || getDefaultLocale());
    return [newUrl, newAs, options];
  };
  return {
    ...router,
    defaultLocale: router?.defaultLocale || i18nConfig.defaultLocale,
    push: (url: any, as?: string, options?: TransitionOptions) => {
      if (url instanceof String) {
        return router.push(...getNewParameters(url, as, options));

      }
      //TODO hack... in this case we dont have a locale in URL
      return router.push(url);
    },
    replace: (url: string, as?: string, options?: TransitionOptions) => {
      return router.replace(...getNewParameters(url, as, options));
    },
    prefetch: (url: string, as?: string, options?: PrefetchOptions) => {
      return router.prefetch(...getNewParameters(url, as, options));
    },
  };
};

export default useRouter;
