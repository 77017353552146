import {reducer as notifications} from 'react-notification-system-redux';
import {progress, ProgressState} from 'react-redux-progress/reducer';
import {Notification} from 'react-notification-system';
import {combineReducers, Reducer} from 'redux';
import app, {initialState as AppInitialState} from '../modules/app/reducer';
import homepage, {initialState as HomepageInitialState} from '../modules/homepage/reducers/index';
import catalog, {initialState as CatalogInitialState} from '../modules/catalog/reducers/index';
import reservation, {initialState as ReservationInitialState} from '../modules/reservation/reducers/index';
import account, {initialState as AccountInitialState} from '../modules/account/reducers/index';
import article, {initialState as ArticleInitialState} from '../modules/article/reducers/index';
import cities, {initialState as CitiesInitialState} from '../modules/cities/reducers/index';
import calendar, {initialState as CalendarInitialState} from '../modules/calendar/reducers/index';
import rental, {initialState as RentalInitalState} from '../modules/rental/reducers/index';


export type ApplicationState = typeof initialState;

const notificationsInitialState: Notification[] = [];

export const initialState = {
  app: AppInitialState,
  homepage: HomepageInitialState,
  catalog: CatalogInitialState,
  reservation: ReservationInitialState,
  account: AccountInitialState,
  article: ArticleInitialState,
  rental: RentalInitalState,
  cities: CitiesInitialState,
  notifications: notificationsInitialState,
  calendar: CalendarInitialState,
  progress: {
    isActive: false,
    activeIds: [],
  } as ProgressState,
};

export default combineReducers<ApplicationState>({
  // INFO: this cast is here because notifications reducer from 'react-notification-system-redux'
  // does not support Reducer signature from 'redux' (please see) - initial state can be undefined and
  notifications: notifications as Reducer<Notification[]>,
  app,
  progress,
  homepage,
  catalog,
  reservation,
  account,
  article,
  cities,
  calendar,
  rental,
});
