export const useHeadMetaData = () => {
  return {
    favicon: '/favicon/favicon.ico',
    appleIcon: '/favicon/apple-touch-icon.png',
    icon32: '/favicon/favicon-32x32.png',
    icon16: '/favicon/favicon-16x16.png',
    safari: '/favicon/safari-pinned-tab.svg',
    manifest: '/favicon/site.webmanifest',
  };
};
