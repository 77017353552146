/**
 * Sets search query string into given url,
 *
 * @note If you need to to set parameters to route url. Please use route.url() function
 */
export function setUrlParams(
    href: string,
    params: { [key: string]: string | string[] | boolean | number | null | undefined }
): string {
  let paramsUrlPart: string = '';
  const paramsPairs: string[] = [];
  let finalParams = {};
  let finalHref = href;
  let finalHash = '';

  if (href.indexOf('#') != -1) {
    const [urlWithoutHash, hashString] = href.split('#');
    finalHash = `#${hashString}`;
    finalHref = urlWithoutHash;
  }

  if (href.indexOf('?') != -1) {
    const [urlWithoutQuestionmark, urlParamsStr] = href.split('?');
    finalHref = urlWithoutQuestionmark;
    finalParams = {
      ...finalParams,
      ...urlParamsStr.split('&').reduce((acc, str) => {
        const [key, val] = str.split('=');
        return { ...acc, [key]: val };
      }, {}),
    };
  }

  finalParams = { ...finalParams, ...params };
  Object.entries<string>(finalParams).forEach(([key, val]) => {
    if (val != null) {
      paramsPairs.push(`${key}=${encodeURIComponent(val)}`);
    }
  });

  if (paramsPairs.length) {
    paramsUrlPart = '?' + paramsPairs.join('&');
  }

  return `${finalHref}${paramsUrlPart}${finalHash}`;
}


export function prepareUrlParams(
    params: any
): string {

  let url = '';

  const paramsPairs: string[] = [];

  Object.entries<string>(params).forEach(([key, val], index) => {
    if (val) {
      paramsPairs.push(`${key}=${encodeURIComponent(val)}`);
    }
  });

  if (paramsPairs.length) {
    url = paramsPairs.join('&');
  }

  return url;
}