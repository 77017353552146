import React, {useMemo} from 'react';
import { QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {DehydratedState, Hydrate} from 'react-query/hydration';
import {useConfiguration} from '../../configuration/useConfiguration';

interface Props {
    dehydratedState?: DehydratedState;
}

export const ReactQueryProvider: React.FC<Props> = ({children, dehydratedState}) => {
    const configuration = useConfiguration();

    const onSuccess = () => {
        //  todo implement https://www.npmjs.com/package/react-notification-system
    };

    // eslint-disable-next-line
    const onError = async (error: unknown) => {
        //  todo implement https://www.npmjs.com/package/react-notification-system
    };

    const createClient = () => {
        return new QueryClient({
            defaultOptions: {
                mutations: {
                    onSuccess,
                    onError,
                    retry: false,
                },
                queries: {
                    onSuccess,
                    onError,
                    refetchOnWindowFocus: false,
                    retry: false,
                    staleTime: configuration.disableCache ? 0 : 5 * 60 * 1000, // 5 minutes till data will be marked as stale
                    cacheTime: configuration.disableCache ? 0 : undefined, // fot cypress tests is essential to disable queries cache
                },
            },
        });
    };

    const queryClient = useMemo(createClient, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>{children}</Hydrate>
            {configuration.nodeEnv != 'production' && <ReactQueryDevtools initialIsOpen={false}/>}
        </QueryClientProvider>
    );
};
