import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {ExtraArgument, State} from 'src/types';
import {GenericResponse} from './models';

type Dispatch<A extends { type: string }> = ThunkDispatch<State, ExtraArgument, Pick<A, 'type'>>;

type ActionFunction<A extends { type: string }> = ThunkAction<Promise<A>, State, ExtraArgument, Pick<A, 'type'>>;

interface GenericAction<R> {
    type: string;
    result?: R;
    error?: any;
}

function createReduxAction<A extends GenericAction<any>, E extends Record<any, any> = Record<string, any>>(args: {
    type: string;
    extras?: E;
    promise: (extraArgument: ExtraArgument) => Promise<A['result']['data']>;
    onSuccess?: (result: A['result'], dispatch: Dispatch<A>, getState: () => State) => void;
    onError?: (error: any, dispatch: Dispatch<A>, getState: () => State) => void;
}): ActionFunction<A> {
    return async (dispatch, getState, extraArgument) => {
        const {type, extras = {}, promise, onSuccess, onError} = args;
        const finishType = `${type}_FINISH`;

        dispatch({type, ...extras});

        try {
          const resp = await promise(extraArgument);
          const data = resp as GenericResponse<any>;

          const result = data.result;
          const error =  data.flashes;

          onSuccess?.(result, dispatch, getState);
          return dispatch({type: finishType, result, error, ...extras} as A);
        } catch (error:any) {
            let finalError = error;

            if (typeof error.json === 'function') {
                finalError = await error.json();
            }

            onError?.(finalError, dispatch, getState);
            return dispatch({type: finishType, error: finalError, ...extras} as A);
        }
    };
}

export default createReduxAction;
