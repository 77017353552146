import {FlashMessage} from "../../common/models";

export interface ReservationOptions {
  sportground: ReservationOptionsSportground,
  categories: ReservationOptionsCategory[],
  options: ReservationOption[],
  paymentMethods: ReservationPaymentMethod[]
  places: ReservationOptionsPlace[],
}

export interface ReservationOptionsCategory {
  id: string,
  name: string
  min: number
  max: number
}

export interface ReservationOptionsSportground {
  id: string,
  name: string,
  preferredCategory: ReservationOptionsCategory | null
}

export interface ReservationOption {
  id: string,
  categoryId: string,
  name: string,
  values: {
    id: string,
    webId: string,
    value: string
  }[]
}

export interface ReservationPaymentMethod {
  id: string,
  name: string,
  image: string,
  type: PaymentMethodType,

}

export interface SearchAvailableSlotsPayload {
  sportgroundId: string,
  categoryId: string,
  date: string,
  timeFrom: string,
  length: number,
  parameters: string[]
}

export interface AvailableSlot {
  placeId: string,
  placeAvailabilityId: string,
  length: number,
  timeFrom: string,
  timeTo: string,
  date: string,
  price: number,
  slots: Slot
}

export interface Slot {
  timeFrom: string,
  timeTo: string
}

export interface ReservationFilterOptions {
  category?: ReservationOptionsCategory | null,
  length: number,
  when?: Date | null,
  parameters: string[]
}

export interface SearchCheaperPriceOnReservationPayload extends SearchAvailableSlotsPayload {
  price: number
}

export interface CheaperReservationPrice {
  price: number,
  interval: string
}

export interface ReservationData {
  amount: number,
  when?: Date | null
  place?: ReservationOptionsPlace | null,
  categoryId?: string | null,
  category?: null | ReservationOptionsCategory,
  length?: number | null,
  slot?: AvailableSlot | null,
  paymentMethod?: string | null
  name?: string | null,
  image?: string | null,
  source?: PaymentSource
  phone?: string | null,
  email?: string | null,
  note?: string | null,
  registerCustomer: boolean
  reservationId?: string
}

export interface ReservationOptionsPlace {
  id: string,
  name: string
}


export enum PaymentSource {
  SPORTYBE = 'SPORTYBE',
  SPORTYBE_RESERVATION = 'SPORTYBE_RESERVATION'
}

export enum PaymentMethodType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  CREDIT = 'CREDIT'
}

export interface ReservationResult {
  id: string
  amount: number,
  category: { id: string, name: string, image: string },
  length: number,
  reservationNumber: number,
  redirectUrl?: string | null,
  sportground: { id: string, name: string, image: string },
  timeFrom: string
  timeTo: string
}

export interface CreateReservationResult {
  error: boolean;
  messages: FlashMessage[],
  reservation?: ReservationResult | null
}

export interface ReservationInfo {
  id: string
  isPaid: boolean,
  amount: number,
  category: { id: string, name: string, image: string },
  length: number,
  reservationNumber: number,
  sportground: { id: string, name: string },
  timeFrom: string
  timeTo: string
}
