import {
  CustomerConditionFinderRow,
  CustomerSeasonTicketDetail,
  CustomerTicketDetail,
  FavoriteCategoryRow,
  FavoriteSportgroundRow,
  ReservationRow,
  SeasonTicketRow, SporgroundConditionDetail,
  TicketRow,
  VirtualWalletTransactionRow
} from '../models';
import {AccountAction} from '../actions/actionTypes';
import {Page} from '../../common/models';
import {AvailableSlot} from "../../reservation/models";

export interface State {
  reservations: Page<ReservationRow> | null
  favoriteSportgrounds: Page<FavoriteSportgroundRow> | null
  favoriteCategories: Page<FavoriteCategoryRow> | null
  wallet: {
    transaction: Page<VirtualWalletTransactionRow> | undefined
  }
  tickets: {
    data: Page<TicketRow> | undefined
    detail: CustomerTicketDetail | undefined
  },
  seasonTickets: {
    data: Page<SeasonTicketRow> | undefined
    detail: CustomerSeasonTicketDetail | undefined
  }
  calendar: {
    validation: {
      result: {
        data: AvailableSlot | undefined,
        error: any[] | undefined
      }
    }
  }
  condition: {
    list: Page<CustomerConditionFinderRow> | undefined
    detail: SporgroundConditionDetail | undefined
  }
}

export const initialState: State = {
  reservations: null,
  favoriteSportgrounds: null,
  favoriteCategories: null,
  wallet: {
    transaction: undefined,
  },
  tickets: {
    data: undefined,
    detail: undefined,
  },
  seasonTickets: {
    data: undefined,
    detail: undefined,
  },
  calendar: {
    validation: {
      result: {
        data: undefined,
        error: undefined
      }
    },
  },
  condition: {
    list: undefined,
    detail: undefined,
  },

};

export default function reducer(state: State = initialState, action: AccountAction): State {
  switch (action.type) {
    case 'RESET_VALIDATE_SLOT_AVAILABILITY_ACTION': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          validation: {
            ...state.calendar.validation,
            result: {
              data: undefined,
              error: undefined
            }
          },
        },
      };
    }
    case 'VALIDATE_SLOT_AVAILABILITY_ACTION_FINISH': {

      return {
        ...state,
        calendar: {
          ...state.calendar,
          validation: {
            ...state.calendar.validation,
            result: {
              data: action.result,
              error: action.error
            }
          },
        },
      };
    }
    case "GET_CUSTOMER_SEASON_TICKET_DETAIL_ACTION_FINISH": {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        seasonTickets: {
          ...state.seasonTickets,
          detail: action.result,
        },
      };
    }
    case 'GET_CUSTOMER_SEASON_TICKETS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        seasonTickets: {
          ...state.seasonTickets,
          data: action.result,
        },
      };
    }
    case "GET_CUSTOMER_TICKET_DETAIL_ACTION_FINISH": {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          detail: action.result,
        },
      };
    }
    case 'GET_CUSTOMER_TICKETS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          data: action.result,
        },
      };
    }
    case 'GET_CUSTOMER_RESERVATION_ACTION_FINISH': {
      return {
        ...state,
        reservations: action.result,
      };
    }
    case 'GET_CUSTOMER_FAVORITE_SPORTGROUND_ACTION_FINISH': {
      return {
        ...state,
        favoriteSportgrounds: action.result,
      };
    }
    case 'GET_CUSTOMER_FAVORITE_CATEGORY_ACTION_FINISH': {
      return {
        ...state,
        favoriteCategories: action.result,
      };
    }
    case 'GET_CUSTOMER_CONDITIONS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }
      return {
        ...state,
        condition: {
          ...state.condition,
          list: action.result,
        },
      };
    }
    case 'GET_CUSTOMER_CONDITION_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }
      return {
        ...state,
        condition: {
          ...state.condition,
          detail: action.result,
        },
      };
    }
    case 'RESET_CUSTOMER_CONDITION_ACTION': {

      return {
        ...state,
        condition: {
          ...state.condition,
          detail: initialState.condition.detail,
        },
      };
    }
    case 'FILTER_VIRTUAL_WALLET_TRANSACTION_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        wallet: {
          ...state.wallet,
          transaction: action.result,
        },
      };
    }
    default:
      return state;
  }
}
