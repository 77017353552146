/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DO NOT EDIT. !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * autogenerated by tools/css/generateDisplay.js
 */
import { sizes } from 'src/styles/mediaQueries';
import { css } from 'styled-components';

const display = css`
  .displayBlock {
    display: block !important;
  }

  .displayFlex {
    display: flex !important;
  }

  .displayInline {
    display: inline !important;
  }

  .displayInlineBlock {
    display: inline-block !important;
  }

  .displayInlineFlex {
    display: inline-flex !important;
  }

  .displayNone {
    display: none !important;
  }

  @media (min-width: ${sizes.sDeviceUp}) {
    .s-displayBlock {
      display: block !important;
    }

    .s-displayFlex {
      display: flex !important;
    }

    .s-displayInline {
      display: inline !important;
    }

    .s-displayInlineBlock {
      display: inline-block !important;
    }

    .s-displayInlineFlex {
      display: inline-flex !important;
    }

    .s-displayNone {
      display: none !important;
    }
  }

  @media (min-width: ${sizes.lDeviceUp}) {
    .xl-displayBlock {
      display: block !important;
    }

    .xl-displayFlex {
      display: flex !important;
    }

    .xl-displayInline {
      display: inline !important;
    }

    .xl-displayInlineBlock {
      display: inline-block !important;
    }

    .xl-displayInlineFlex {
      display: inline-flex !important;
    }

    .xl-displayNone {
      display: none !important;
    }
  }

  @media print {
    .displayPrintNone {
      display: none !important;
    }
  }
`;

export default display;
