/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DO NOT EDIT. !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * autogenerated by tools/css/generateSpacing.js
 */
import { sizes } from 'src/styles/mediaQueries';
import { css } from 'styled-components';

const spacing = css`
  .p--0 {
    padding: 0 !important;
  }

  .p--4 {
    padding: 4px !important;
  }

  .p--8 {
    padding: 8px !important;
  }

  .p--12 {
    padding: 12px !important;
  }

  .p--16 {
    padding: 16px !important;
  }

  .p--20 {
    padding: 20px !important;
  }

  .p--24 {
    padding: 24px !important;
  }

  .p--32 {
    padding: 32px !important;
  }

  .p--40 {
    padding: 40px !important;
  }

  .pt--0 {
    padding-top: 0 !important;
  }

  .pt--4 {
    padding-top: 4px !important;
  }

  .pt--8 {
    padding-top: 8px !important;
  }

  .pt--12 {
    padding-top: 12px !important;
  }

  .pt--16 {
    padding-top: 16px !important;
  }

  .pt--20 {
    padding-top: 20px !important;
  }

  .pt--24 {
    padding-top: 24px !important;
  }

  .pt--32 {
    padding-top: 32px !important;
  }

  .pt--40 {
    padding-top: 40px !important;
  }

  .pr--0 {
    padding-right: 0 !important;
  }

  .pr--4 {
    padding-right: 4px !important;
  }

  .pr--8 {
    padding-right: 8px !important;
  }

  .pr--12 {
    padding-right: 12px !important;
  }

  .pr--16 {
    padding-right: 16px !important;
  }

  .pr--20 {
    padding-right: 20px !important;
  }

  .pr--24 {
    padding-right: 24px !important;
  }

  .pr--32 {
    padding-right: 32px !important;
  }

  .pr--40 {
    padding-right: 40px !important;
  }

  .pb--0 {
    padding-bottom: 0 !important;
  }

  .pb--4 {
    padding-bottom: 4px !important;
  }

  .pb--8 {
    padding-bottom: 8px !important;
  }

  .pb--12 {
    padding-bottom: 12px !important;
  }

  .pb--16 {
    padding-bottom: 16px !important;
  }

  .pb--20 {
    padding-bottom: 20px !important;
  }

  .pb--24 {
    padding-bottom: 24px !important;
  }

  .pb--32 {
    padding-bottom: 32px !important;
  }

  .pb--40 {
    padding-bottom: 40px !important;
  }

  .pl--0 {
    padding-left: 0 !important;
  }

  .pl--4 {
    padding-left: 4px !important;
  }

  .pl--8 {
    padding-left: 8px !important;
  }

  .pl--12 {
    padding-left: 12px !important;
  }

  .pl--16 {
    padding-left: 16px !important;
  }

  .pl--20 {
    padding-left: 20px !important;
  }

  .pl--24 {
    padding-left: 24px !important;
  }

  .pl--32 {
    padding-left: 32px !important;
  }

  .pl--40 {
    padding-left: 40px !important;
  }

  .m--0 {
    margin: 0 !important;
  }

  .m--4 {
    margin: 4px !important;
  }

  .m--8 {
    margin: 8px !important;
  }

  .m--12 {
    margin: 12px !important;
  }

  .m--16 {
    margin: 16px !important;
  }

  .m--20 {
    margin: 20px !important;
  }

  .m--24 {
    margin: 24px !important;
  }

  .m--32 {
    margin: 32px !important;
  }

  .m--40 {
    margin: 40px !important;
  }

  .mt--0 {
    margin-top: 0 !important;
  }

  .mt--4 {
    margin-top: 4px !important;
  }

  .mt--8 {
    margin-top: 8px !important;
  }

  .mt--12 {
    margin-top: 12px !important;
  }

  .mt--16 {
    margin-top: 16px !important;
  }

  .mt--20 {
    margin-top: 20px !important;
  }

  .mt--24 {
    margin-top: 24px !important;
  }

  .mt--32 {
    margin-top: 32px !important;
  }

  .mt--40 {
    margin-top: 40px !important;
  }

  .mr--0 {
    margin-right: 0 !important;
  }

  .mr--4 {
    margin-right: 4px !important;
  }

  .mr--8 {
    margin-right: 8px !important;
  }

  .mr--12 {
    margin-right: 12px !important;
  }

  .mr--16 {
    margin-right: 16px !important;
  }

  .mr--20 {
    margin-right: 20px !important;
  }

  .mr--24 {
    margin-right: 24px !important;
  }

  .mr--32 {
    margin-right: 32px !important;
  }

  .mr--40 {
    margin-right: 40px !important;
  }

  .mb--0 {
    margin-bottom: 0 !important;
  }

  .mb--4 {
    margin-bottom: 4px !important;
  }

  .mb--8 {
    margin-bottom: 8px !important;
  }

  .mb--12 {
    margin-bottom: 12px !important;
  }

  .mb--16 {
    margin-bottom: 16px !important;
  }

  .mb--20 {
    margin-bottom: 20px !important;
  }

  .mb--24 {
    margin-bottom: 24px !important;
  }

  .mb--32 {
    margin-bottom: 32px !important;
  }

  .mb--40 {
    margin-bottom: 40px !important;
  }

  .ml--0 {
    margin-left: 0 !important;
  }

  .ml--4 {
    margin-left: 4px !important;
  }

  .ml--8 {
    margin-left: 8px !important;
  }

  .ml--12 {
    margin-left: 12px !important;
  }

  .ml--16 {
    margin-left: 16px !important;
  }

  .ml--20 {
    margin-left: 20px !important;
  }

  .ml--24 {
    margin-left: 24px !important;
  }

  .ml--32 {
    margin-left: 32px !important;
  }

  .ml--40 {
    margin-left: 40px !important;
  }

  @media (min-width: ${sizes.sDeviceUp}) {
    .s-p--0 {
      padding: 0 !important;
    }

    .s-p--4 {
      padding: 4px !important;
    }

    .s-p--8 {
      padding: 8px !important;
    }

    .s-p--12 {
      padding: 12px !important;
    }

    .s-p--16 {
      padding: 16px !important;
    }

    .s-p--20 {
      padding: 20px !important;
    }

    .s-p--24 {
      padding: 24px !important;
    }

    .s-p--32 {
      padding: 32px !important;
    }

    .s-p--40 {
      padding: 40px !important;
    }

    .s-pt--0 {
      padding-top: 0 !important;
    }

    .s-pt--4 {
      padding-top: 4px !important;
    }

    .s-pt--8 {
      padding-top: 8px !important;
    }

    .s-pt--12 {
      padding-top: 12px !important;
    }

    .s-pt--16 {
      padding-top: 16px !important;
    }

    .s-pt--20 {
      padding-top: 20px !important;
    }

    .s-pt--24 {
      padding-top: 24px !important;
    }

    .s-pt--32 {
      padding-top: 32px !important;
    }

    .s-pt--40 {
      padding-top: 40px !important;
    }

    .s-pr--0 {
      padding-right: 0 !important;
    }

    .s-pr--4 {
      padding-right: 4px !important;
    }

    .s-pr--8 {
      padding-right: 8px !important;
    }

    .s-pr--12 {
      padding-right: 12px !important;
    }

    .s-pr--16 {
      padding-right: 16px !important;
    }

    .s-pr--20 {
      padding-right: 20px !important;
    }

    .s-pr--24 {
      padding-right: 24px !important;
    }

    .s-pr--32 {
      padding-right: 32px !important;
    }

    .s-pr--40 {
      padding-right: 40px !important;
    }

    .s-pb--0 {
      padding-bottom: 0 !important;
    }

    .s-pb--4 {
      padding-bottom: 4px !important;
    }

    .s-pb--8 {
      padding-bottom: 8px !important;
    }

    .s-pb--12 {
      padding-bottom: 12px !important;
    }

    .s-pb--16 {
      padding-bottom: 16px !important;
    }

    .s-pb--20 {
      padding-bottom: 20px !important;
    }

    .s-pb--24 {
      padding-bottom: 24px !important;
    }

    .s-pb--32 {
      padding-bottom: 32px !important;
    }

    .s-pb--40 {
      padding-bottom: 40px !important;
    }

    .s-pl--0 {
      padding-left: 0 !important;
    }

    .s-pl--4 {
      padding-left: 4px !important;
    }

    .s-pl--8 {
      padding-left: 8px !important;
    }

    .s-pl--12 {
      padding-left: 12px !important;
    }

    .s-pl--16 {
      padding-left: 16px !important;
    }

    .s-pl--20 {
      padding-left: 20px !important;
    }

    .s-pl--24 {
      padding-left: 24px !important;
    }

    .s-pl--32 {
      padding-left: 32px !important;
    }

    .s-pl--40 {
      padding-left: 40px !important;
    }

    .s-m--0 {
      margin: 0 !important;
    }

    .s-m--4 {
      margin: 4px !important;
    }

    .s-m--8 {
      margin: 8px !important;
    }

    .s-m--12 {
      margin: 12px !important;
    }

    .s-m--16 {
      margin: 16px !important;
    }

    .s-m--20 {
      margin: 20px !important;
    }

    .s-m--24 {
      margin: 24px !important;
    }

    .s-m--32 {
      margin: 32px !important;
    }

    .s-m--40 {
      margin: 40px !important;
    }

    .s-mt--0 {
      margin-top: 0 !important;
    }

    .s-mt--4 {
      margin-top: 4px !important;
    }

    .s-mt--8 {
      margin-top: 8px !important;
    }

    .s-mt--12 {
      margin-top: 12px !important;
    }

    .s-mt--16 {
      margin-top: 16px !important;
    }

    .s-mt--20 {
      margin-top: 20px !important;
    }

    .s-mt--24 {
      margin-top: 24px !important;
    }

    .s-mt--32 {
      margin-top: 32px !important;
    }

    .s-mt--40 {
      margin-top: 40px !important;
    }

    .s-mr--0 {
      margin-right: 0 !important;
    }

    .s-mr--4 {
      margin-right: 4px !important;
    }

    .s-mr--8 {
      margin-right: 8px !important;
    }

    .s-mr--12 {
      margin-right: 12px !important;
    }

    .s-mr--16 {
      margin-right: 16px !important;
    }

    .s-mr--20 {
      margin-right: 20px !important;
    }

    .s-mr--24 {
      margin-right: 24px !important;
    }

    .s-mr--32 {
      margin-right: 32px !important;
    }

    .s-mr--40 {
      margin-right: 40px !important;
    }

    .s-mb--0 {
      margin-bottom: 0 !important;
    }

    .s-mb--4 {
      margin-bottom: 4px !important;
    }

    .s-mb--8 {
      margin-bottom: 8px !important;
    }

    .s-mb--12 {
      margin-bottom: 12px !important;
    }

    .s-mb--16 {
      margin-bottom: 16px !important;
    }

    .s-mb--20 {
      margin-bottom: 20px !important;
    }

    .s-mb--24 {
      margin-bottom: 24px !important;
    }

    .s-mb--32 {
      margin-bottom: 32px !important;
    }

    .s-mb--40 {
      margin-bottom: 40px !important;
    }

    .s-ml--0 {
      margin-left: 0 !important;
    }

    .s-ml--4 {
      margin-left: 4px !important;
    }

    .s-ml--8 {
      margin-left: 8px !important;
    }

    .s-ml--12 {
      margin-left: 12px !important;
    }

    .s-ml--16 {
      margin-left: 16px !important;
    }

    .s-ml--20 {
      margin-left: 20px !important;
    }

    .s-ml--24 {
      margin-left: 24px !important;
    }

    .s-ml--32 {
      margin-left: 32px !important;
    }

    .s-ml--40 {
      margin-left: 40px !important;
    }
  }

  @media (min-width: ${sizes.lDeviceUp}) {
    .xl-p--0 {
      padding: 0 !important;
    }

    .xl-p--4 {
      padding: 4px !important;
    }

    .xl-p--8 {
      padding: 8px !important;
    }

    .xl-p--12 {
      padding: 12px !important;
    }

    .xl-p--16 {
      padding: 16px !important;
    }

    .xl-p--20 {
      padding: 20px !important;
    }

    .xl-p--24 {
      padding: 24px !important;
    }

    .xl-p--32 {
      padding: 32px !important;
    }

    .xl-p--40 {
      padding: 40px !important;
    }

    .xl-pt--0 {
      padding-top: 0 !important;
    }

    .xl-pt--4 {
      padding-top: 4px !important;
    }

    .xl-pt--8 {
      padding-top: 8px !important;
    }

    .xl-pt--12 {
      padding-top: 12px !important;
    }

    .xl-pt--16 {
      padding-top: 16px !important;
    }

    .xl-pt--20 {
      padding-top: 20px !important;
    }

    .xl-pt--24 {
      padding-top: 24px !important;
    }

    .xl-pt--32 {
      padding-top: 32px !important;
    }

    .xl-pt--40 {
      padding-top: 40px !important;
    }

    .xl-pr--0 {
      padding-right: 0 !important;
    }

    .xl-pr--4 {
      padding-right: 4px !important;
    }

    .xl-pr--8 {
      padding-right: 8px !important;
    }

    .xl-pr--12 {
      padding-right: 12px !important;
    }

    .xl-pr--16 {
      padding-right: 16px !important;
    }

    .xl-pr--20 {
      padding-right: 20px !important;
    }

    .xl-pr--24 {
      padding-right: 24px !important;
    }

    .xl-pr--32 {
      padding-right: 32px !important;
    }

    .xl-pr--40 {
      padding-right: 40px !important;
    }

    .xl-pb--0 {
      padding-bottom: 0 !important;
    }

    .xl-pb--4 {
      padding-bottom: 4px !important;
    }

    .xl-pb--8 {
      padding-bottom: 8px !important;
    }

    .xl-pb--12 {
      padding-bottom: 12px !important;
    }

    .xl-pb--16 {
      padding-bottom: 16px !important;
    }

    .xl-pb--20 {
      padding-bottom: 20px !important;
    }

    .xl-pb--24 {
      padding-bottom: 24px !important;
    }

    .xl-pb--32 {
      padding-bottom: 32px !important;
    }

    .xl-pb--40 {
      padding-bottom: 40px !important;
    }

    .xl-pl--0 {
      padding-left: 0 !important;
    }

    .xl-pl--4 {
      padding-left: 4px !important;
    }

    .xl-pl--8 {
      padding-left: 8px !important;
    }

    .xl-pl--12 {
      padding-left: 12px !important;
    }

    .xl-pl--16 {
      padding-left: 16px !important;
    }

    .xl-pl--20 {
      padding-left: 20px !important;
    }

    .xl-pl--24 {
      padding-left: 24px !important;
    }

    .xl-pl--32 {
      padding-left: 32px !important;
    }

    .xl-pl--40 {
      padding-left: 40px !important;
    }

    .xl-m--0 {
      margin: 0 !important;
    }

    .xl-m--4 {
      margin: 4px !important;
    }

    .xl-m--8 {
      margin: 8px !important;
    }

    .xl-m--12 {
      margin: 12px !important;
    }

    .xl-m--16 {
      margin: 16px !important;
    }

    .xl-m--20 {
      margin: 20px !important;
    }

    .xl-m--24 {
      margin: 24px !important;
    }

    .xl-m--32 {
      margin: 32px !important;
    }

    .xl-m--40 {
      margin: 40px !important;
    }

    .xl-mt--0 {
      margin-top: 0 !important;
    }

    .xl-mt--4 {
      margin-top: 4px !important;
    }

    .xl-mt--8 {
      margin-top: 8px !important;
    }

    .xl-mt--12 {
      margin-top: 12px !important;
    }

    .xl-mt--16 {
      margin-top: 16px !important;
    }

    .xl-mt--20 {
      margin-top: 20px !important;
    }

    .xl-mt--24 {
      margin-top: 24px !important;
    }

    .xl-mt--32 {
      margin-top: 32px !important;
    }

    .xl-mt--40 {
      margin-top: 40px !important;
    }

    .xl-mr--0 {
      margin-right: 0 !important;
    }

    .xl-mr--4 {
      margin-right: 4px !important;
    }

    .xl-mr--8 {
      margin-right: 8px !important;
    }

    .xl-mr--12 {
      margin-right: 12px !important;
    }

    .xl-mr--16 {
      margin-right: 16px !important;
    }

    .xl-mr--20 {
      margin-right: 20px !important;
    }

    .xl-mr--24 {
      margin-right: 24px !important;
    }

    .xl-mr--32 {
      margin-right: 32px !important;
    }

    .xl-mr--40 {
      margin-right: 40px !important;
    }

    .xl-mb--0 {
      margin-bottom: 0 !important;
    }

    .xl-mb--4 {
      margin-bottom: 4px !important;
    }

    .xl-mb--8 {
      margin-bottom: 8px !important;
    }

    .xl-mb--12 {
      margin-bottom: 12px !important;
    }

    .xl-mb--16 {
      margin-bottom: 16px !important;
    }

    .xl-mb--20 {
      margin-bottom: 20px !important;
    }

    .xl-mb--24 {
      margin-bottom: 24px !important;
    }

    .xl-mb--32 {
      margin-bottom: 32px !important;
    }

    .xl-mb--40 {
      margin-bottom: 40px !important;
    }

    .xl-ml--0 {
      margin-left: 0 !important;
    }

    .xl-ml--4 {
      margin-left: 4px !important;
    }

    .xl-ml--8 {
      margin-left: 8px !important;
    }

    .xl-ml--12 {
      margin-left: 12px !important;
    }

    .xl-ml--16 {
      margin-left: 16px !important;
    }

    .xl-ml--20 {
      margin-left: 20px !important;
    }

    .xl-ml--24 {
      margin-left: 24px !important;
    }

    .xl-ml--32 {
      margin-left: 32px !important;
    }

    .xl-ml--40 {
      margin-left: 40px !important;
    }
  }
`;

export default spacing;
