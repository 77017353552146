import Head from 'next/head';
import React from 'react';
import {useSelector} from 'react-redux';
import {useConfiguration} from '../../../configuration/useConfiguration';
import useTranslations from '../../../utils/hooks/useTranslations';

import {selectAppMetaData} from '../reducer/selectors';
import {useHeadMetaData} from '../../../utils/hooks/useHeadMetaData';

interface ComponentProps {
    hasTitleAppend?: boolean;
    robots?: boolean;
    title?: string;
}

const HeadMetaData: React.FC<ComponentProps> = () => {
    const {brandName, author, cdnUrl} = useConfiguration();

    const metaData = useSelector(selectAppMetaData);

    const {favicon, icon16, icon32, appleIcon, safari, manifest} = useHeadMetaData();

    let title = metaData.title || 'SportyBe';
    let description = metaData.description || 'Pomáháme s rozvojem pohybové aktivity způsobem, jenž vám ulehčuje rezervaci vašeho sportu ve správný čas';

    const pageTitle = title + ' | sportybe.com - Buď v obraze. Buď SportyBe';

    return <>
        <Head>
            <title>{pageTitle}</title>

            <meta content={author} name="author"/>
            <meta content={brandName} property="og:site_name"/>

            <link rel="shortcut icon" href={favicon}/>
            <link rel="apple-touch-icon" sizes="180x180" href={appleIcon}/>
            <link rel="icon" type="image/png" sizes="32x32" href={icon32}/>
            <link rel="icon" type="image/png" sizes="16x16" href={icon16}/>
            <link rel="manifest" href={manifest}/>
            <link rel="mask-icon" href={safari} color="#f3bc51"/>
            <meta content="width=device-width,initial-scale=1.0,user-scalable=no,maximum-scale=1" name="viewport"/>
            <script
                type="text/javascript"
                src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            />
            <meta content={title} property="og:title"/>
            {description && (
                <>
                    <meta content={description} name="description"/>
                    <meta content={description} property="og:description"/>
                </>
            )}

            {metaData.image && <>
                <meta content={cdnUrl + metaData.image} property='og:image'/>
                <meta content='1200' property='og:image:width'/>
                <meta content='630' property='og:image:height'/>
            </>}
            {!metaData.robots && <meta content="noindex" name="robots"/>}
            {metaData.robots && <meta content={`${metaData.robots}`} name="robots"/>}

        </Head>
    </>;
};

export default HeadMetaData;
