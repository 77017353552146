import { css } from 'styled-components';
import { sizes } from 'src/styles/mediaQueries';

const typography = css`
  .typographyLeft {
    text-align: left !important;
  }

  .typographyCenter {
    text-align: center !important;
  }

  .typographyRight {
    text-align: right !important;
  }

  .typographyLinkReset {
    color: inherit !important;
    text-decoration: none !important;
  }

  .typographyUnderline {
    text-decoration: underline !important;
  }

  .typographyUnderlineOnHover {
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .typograhpyMediumWeight {
    font-weight: 600 !important;
  }

  .typographyItalic {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: italic;
  }

  .typographyUppercase {
    text-transform: uppercase;
  }

  @media (min-width: ${sizes.sDeviceUp}) {
    .s-typographyLeft {
      text-align: left !important;
    }

    .s-typographyCenter {
      text-align: center !important;
    }

    .s-typographyRight {
      text-align: right !important;
    }
  }
`;

export default typography;
