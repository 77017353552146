import {Action, ActionFunction} from 'src/types';
import createReduxAction from "../../common/actionCreator";

export function setAppMetaData(
  title?: Nullable<string>,
  image?: Nullable<string>,
  description?: Nullable<string>,
  robots?: string | null,
): Action {

  return {
    type: 'SET_APP_META_DATA',
    metaData: {
      title,
      image,
      description,
      robots,
    },
  };
}

export function resetAppMetaData(): Action {
  return {
    type: 'RESET_APP_META_DATA',
  };
}

export function setCurrentPageType(pageType: Nullable<string>): Action {
  return {
    type: 'SET_CURRENT_PAGE_TYPE',
    pageType,
  };
}


export function resolveSeoUrl(
  payload: { resourceType: string, value: string, sportgroundId?: string | null }
): ActionFunction<Action> {

  return createReduxAction({
    type: 'RESOLVE_SEO_URL_ACTION',
    promise: ({api}) => api.post('RESOLVE_SEO_URL', {
      data: payload
    }),
  });
}

export function setRefreshList(value: string): {type:any,value:any} {
  return {
    type: 'SET_REFRESH_LIST_ACTION',
    value,
  };
}

export function getBasket(sportgroundId: string): ActionFunction<Action> {

  return createReduxAction({
    type: 'GET_BASKET_ACTION',
    promise: ({api}) =>
      api.get('GET_BASKET', {
        urlParts: [sportgroundId],
      }),
  });
}


export function getOrder(orderId: string): ActionFunction<Action> {

  return createReduxAction({
    type: 'GET_ORDER_ACTION',
    promise: ({api}) =>
      api.get('ORDER_BY_ID', {
        urlParts: [orderId],
      }),
  });
}
