import { css } from 'styled-components';

const lists = css`
  ul,
  ul li {
    list-style: none;
  }

  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
  }

  li:last-child {
    margin: 0;
  }
`;

export default lists;
