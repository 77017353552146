/*
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DO NOT EDIT. !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * autogenerated by tools/css/generateBlock.js
 */
import { sizes } from 'src/styles/mediaQueries';
import { css } from 'styled-components';

const block = css`
  .blockCenter {
    margin: 0 auto !important;
  }

  .blockAlignItemsCenter {
    align-items: center !important;
  }

  .blockAlignItemsBaseline {
    align-items: baseline !important;
  }

  .blockJustifyContentSpaceBetween {
    justify-content: space-between !important;
  }

  .blockJustifyContentSpaceAround {
    justify-content: space-around !important;
  }

  .blockJustifyContentCenter {
    justify-content: center !important;
  }

  @media (min-width: ${sizes.sDeviceUp}) {
    .s-blockCenter {
      margin: 0 auto !important;
    }

    .s-blockAlignItemsCenter {
      align-items: center !important;
    }

    .s-blockAlignItemsBaseline {
      align-items: baseline !important;
    }

    .s-blockJustifyContentSpaceBetween {
      justify-content: space-between !important;
    }

    .s-blockJustifyContentSpaceAround {
      justify-content: space-around !important;
    }

    .s-blockJustifyContentCenter {
      justify-content: center !important;
    }
  }

  @media (min-width: ${sizes.lDeviceUp}) {
    .xl-blockCenter {
      margin: 0 auto !important;
    }

    .xl-blockAlignItemsCenter {
      align-items: center !important;
    }

    .xl-blockAlignItemsBaseline {
      align-items: baseline !important;
    }

    .xl-blockJustifyContentSpaceBetween {
      justify-content: space-between !important;
    }

    .xl-blockJustifyContentSpaceAround {
      justify-content: space-around !important;
    }

    .xl-blockJustifyContentCenter {
      justify-content: center !important;
    }
  }
`;

export default block;
