import i18nConfig from '../../../i18n';

interface DomainConfig {
  domain: string;
  defaultLocale: string;
  otherLocales?: string[];
}

const getDomainConfig = (domain: string | null | undefined): DomainConfig | undefined => {
  return i18nConfig.domains?.find((d: DomainConfig) => domain === d.domain);
};

const getAllDomainLanguages = (domain: string | null | undefined): string[] => {
  const domainConf = getDomainConfig(domain);
  if (!domainConf) {
    return [getDefaultLocale()];
  }
  return [domainConf.defaultLocale, ...(domainConf.otherLocales || [])];
};

export const getDefaultLocale = (): string => {
  return i18nConfig.defaultLocale;
};

export const isSupportedLanguage = (locale: string, domain?: string | null | undefined): boolean => {
  const globalSupported = i18nConfig.locales.includes(locale);
  if (!domain) {
    return globalSupported;
  }

  const domainConf = getDomainConfig(domain);
  if (!domainConf) {
    return globalSupported;
  }

  return getAllDomainLanguages(domain).includes(locale);
};

export const getUrlPrefix = (domain: string | null | undefined, locale: string): string | null => {
  // return locale;
  if (!isSupportedLanguage(locale, domain)) {
    return null;
  }

  if (getDefaultLocale() === locale) {
    return '';
  }

  return '/' + locale;
};

export const getUrlWithLanguagePrefix = (
  pathname: string,
  domain: string | null | undefined,
  locale: string
): string => {
  if (!pathname.startsWith('/')) {
    // link is external -> do not modify it
    return pathname;
  }

  const lngPrefix = getUrlPrefix(domain, locale);

  if (lngPrefix === null) {
    return pathname;
  }

  const splited = pathname.split('?');
  let path = splited[0];
  const params = splited[1] || '';

  const urlLng = path.match(/\/[a-z]{2}-[A-Z]{2}[/$]?/)?.[0];

  if (urlLng) {
    path = path.replace(urlLng, '/');
  }

  let result = `${lngPrefix}${path}${params ? '?' + params : ''}`;
  if (result.indexOf('/?') > 0) {
    result = result.replace('/?', '?');
  }

  return result;
};

export const getLanguageTitle = (lng: string): string => {
  return i18nConfig.localeTitle[lng as keyof typeof i18nConfig.localeTitle] || '';
};
