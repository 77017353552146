export const XS_WIDTH_DOWN = 360;
export const XS_WIDTH = 361;

export const S_WIDTH_DOWN = 566;
export const S_WIDTH = 567;

export const M_WIDTH_DOWN = 767;
export const M_WIDTH = 768;

export const ML_WIDTH_DOWN = 959;
export const ML_WIDTH = 960;

export const L_WIDTH_DOWN = 1023;
export const L_WIDTH = 1024;

export const XL_WIDTH_DOWN = 1259;
export const XL_WIDTH = 1260;

export const XXL_WIDTH_DOWN = 1679;
export const XXL_WIDTH = 1680;

export const XXXL_WIDTH_DOWN = 1760;
export const XXXL_WIDTH = 1761;

export const XXXXL_WIDTH_DOWN = 1920;
export const XXXXL_WIDTH = 1921;
