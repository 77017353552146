import {AggregateCity} from '../models';
import {Page} from '../../common/models';
import {CitiesAction} from '../actions/actionTypes';

export interface State {
    aggregatedRows: Page<AggregateCity> | null
}


export const initialState: State = {
    aggregatedRows: null
}

export default function reducer(state: State = initialState, action: CitiesAction): State {
    switch (action.type) {
        case 'SEARCH_CITIES_ACTION_FINISH': {
            return {
                ...state,
                aggregatedRows: action.result
            };
        }
        default:
            return state;
    }
}
