import { css } from 'styled-components';

const images = css`
  img {
    max-width: 100%;
    box-sizing: initial;
  }
`;

export default images;
