import { getDefaultLocale } from '../../lib/i18n/utils';
import Base from './base';

const GlobalStyle: React.FC = () => {
  const language = getDefaultLocale().split('-')[0];

  return <Base language={language} />;
};

export default GlobalStyle;
