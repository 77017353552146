import { ApiEndpointName } from './config';

export interface UrlParams {
  [name: string]: Nullable<string | number | boolean>;
}

export interface Options {
  data?: Record<string, any>;
  formData?: FormData;
  urlParams?: UrlParams;
  urlParts?: Array<string | number>;
  concurent?: number;
  timeout?: number;
  headers?: Nullable<Record<string, any>>;
}

export type ApiFunction = <R = any>(actionName: ApiEndpointName, options?: Options) => Promise<R>;

export enum ApiMethod {
  'get' = 'get',
  'post' = 'post',
  'put' = 'put',
  'patch' = 'patch',
  'delete' = 'delete',
}
