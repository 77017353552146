import { css } from 'styled-components';

const links = css(
  ({ theme }) => css`
    a {
      color: ${theme.colorTextPrimary};
      text-decoration: none;

      &:hover {
        color: ${theme.colorTextPrimary};
      }
    }
  `
);

export default links;
