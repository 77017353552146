export function isServer(): boolean {
  return typeof window === 'undefined';
}

export function stripHTML(text: string): string {
  return text
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/[\n\r]/g, '')
    .replace(/&nbsp;/g, ' ');
}

export const between = (x: number, min: number, max: number, exclusive: boolean = true): boolean => {
  if (exclusive) {
    return x > min && x < max;
  } else {
    return x >= min && x <= max;
  }
};
