import { sizes } from 'src/styles/mediaQueries';
import { css } from 'styled-components';

const input = css(
  ({ theme }) => css`
    input {
      width: 100%;
      border: 1px solid ${theme.colors.grey[60]};
      padding: 0 16px;
      height: 45px;
      border-radius: ${theme.borderRadiusMedium};
      background: ${theme.backgroundColorPrimary};
      outline: none;

      &:focus {
        border: 1px solid ${theme.borderColorBrand};
      }

      @media (max-width: ${sizes.sDevice}) {
        &[type='text'],
        &[type='password'],
        &[type='email'],
        &[type='number'],
        &[type='tel'] {
          font-size: 16px;
        }
      }
    }

    input[type='radio'] {
      height: auto;
      line-height: normal;
      display: inline-block;
      width: auto;
      opacity: 0;
      position: absolute;
    }
  `
);

export default input;
