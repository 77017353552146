import { AppMetaData, Cookie } from '../../../types';
import { AppAction } from '../actions/actionTypes';
import { emptyAppMetaData } from '../../../types/empty';
import {Basket, Coordinates, Order} from '../models';

export interface AppState {
  currentPageType: Nullable<string>;
  metaData: AppMetaData;
  cookies: { [cookie in Cookie]: any };
  coordinates: Coordinates | null
  refreshValue: string | undefined,
  basket: Basket | undefined,
  order: {
    detail: Order | undefined
  }
}

export const initialState: AppState = {
  currentPageType: null,
  metaData: emptyAppMetaData(),
  cookies: {} as AppState['cookies'],
  coordinates: null,
  refreshValue: undefined,
  basket: undefined,
  order: {
    detail: undefined,
  },
};

export default function reducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case 'GET_BASKET_ACTION_FINISH': {
      return {
        ...state,
        basket: action.result,
      };
    }
    case 'GET_ORDER_ACTION_FINISH': {
      return {
        ...state,
        order: {
          ...state.order,
          detail: action.result,
        },
      };
    }
    case 'SET_REFRESH_LIST_ACTION': {
      return {
        ...state,
        refreshValue: action.value,
      };
    }
    case 'RESET_APP_META_DATA': {
      return {
        ...state,
        metaData: emptyAppMetaData()
      }
    }
    case 'SET_APP_META_DATA': {
      const { metaData } = action;

      return {
        ...state,
        metaData: Object.keys(metaData).reduce<AppMetaData>((acc, key) => {
          const k = key as keyof AppMetaData;
          if (metaData[k] != undefined) {
            acc = { ...acc, [k]: metaData[k] };
          }
          return acc;
        }, state.metaData),
      };
    }

    case 'SET_CURRENT_PAGE_TYPE': {
      if (action.pageType === state.currentPageType) {
        return state;
      }

      return {
        ...state,
        currentPageType: action.pageType,
      };
    }
    case 'SET_COOKIES': {
      return {
        ...state,
        cookies: action.cookies,
      };
    }
    case 'SET_COORDINATES': {
      return {
        ...state,
        coordinates: action.coordinates,
      };
    }

    default:
      return state;
  }
}
