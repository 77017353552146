import { css } from 'styled-components';

const button = css(
  ({ theme }) => css`
    button {
      background: ${theme.backgroundColorBrand};
      padding: 0 20px;
      font-weight: 600;
      border: 0;
      border-radius: 4px;
      height: 45px;
      line-height: 45px;
      color: ${theme.colorTextInverted};

      &:hover {
        background: ${theme.backgroundColorBrandDark};
      }
    }
  `
);

export default button;
