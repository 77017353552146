import { css } from 'styled-components';

const select = css(
  ({ theme }) => css`
    select {
      width: 100%;
      height: 45px;
      line-height: 45px;
      border-radius: 2px;
      border-color: ${theme.colors.grey[60]};

      &:focus {
        border: 1px solid ${theme.colors.green[60]};
      }
    }
  `
);

export default select;
