/**
 * @see https://nextjs.org/docs/advanced-features/i18n-routing (Next.js doc)
 * @see https://github.com/vinissimus/next-translate/blob/master/README.md (next-translate doc)
 */
module.exports = {
    locales: ['cs-CZ', 'en-GB'],
    defaultLocale: process.env.DEFAULT_LOCALE || 'cs-CZ',
    defaultNamespace: 'common',
    localeDetection: false,
    loadLocaleFrom: async (lang, ns, params = {}) => {
        if (typeof window === 'undefined') {
            return import(`./locales/${lang}/${ns}`).then((r) => r.default);
        }

        const {releaseId = window.releaseId} = params;
        const releaseIdStr = releaseId ? '?v=' + releaseId : '';
        const resp = await fetch(`/locales/${lang}/${ns}.json${releaseIdStr}`);
        return resp.json();
    },
    domains: [
        {
            domain: 'sportybe.cz',
            defaultLocale: 'cs-CZ',
            otherLocales: ['en-GB'],
        },
    ],
    pages: {
        '*': ['common', 'navigation', 'about', 'footer', 'breadCrumb', 'datepicker', 'sidebar'],
        '/': ['homepage', 'common', 'breadCrumb'],
        '/category': ['category', 'breadCrumb'],
        '/sportground': ['sportground', 'homepage', 'breadCrumb'],
        '/apps': ['apps', 'breadCrumb'],
        '/faq': ['faq', 'breadCrumb'],
        '/sportgroundList': ['sportground', 'catalog', 'breadCrumb'],
        '/catalogList': ['catalog','sportground', 'breadCrumb'],
        '/categoryList': ['category','breadCrumb'],
        '/article': ['article','breadCrumb'],
        '/articles': ['article','breadCrumb'],
        '/user/login': ['login','breadCrumb'],
        '/ticketList': ['user','breadCrumb'],
        '/user/credit': ['user','breadCrumb'],
        '/user/favorite': ['user','breadCrumb'],
        '/forgottenPasswordOne': ['user','breadCrumb'],
        '/forgottenPasswordTwo': ['user','breadCrumb'],
        '/user/friends': ['user','breadCrumb'],
        '/user/permanentList': ['user','breadCrumb'],
        '/user/reservations': ['reservationList','breadCrumb'],
        '/reservationFirstStep': ['reservation','homepage','breadCrumb'],
        '/reservationSecondStep': ['reservation','homepage','breadCrumb'],
        '/reservationFinalize': ['reservation','homepage','breadCrumb'],
        '/reservationCancel': ['reservation','homepage','breadCrumb'],

        '/ticketFirstStep': ['reservation','homepage','breadCrumb'],
        '/ticketSecondStep': ['reservation','homepage','breadCrumb'],
        '/ticketFinalize': ['reservation','homepage','breadCrumb'],
        '/user/settings': ['settings','breadCrumb'],
        '/newPasswordOne': ['user','breadCrumb'],
        '/newPasswordTwo': ['user','breadCrumb'],
        '/registerSuccess': ['register','breadCrumb'],
        '/user/register': ['register','user', 'login','breadCrumb'],
        '/map': ['sportground', 'maps','breadCrumb'],
        '/404': ['error','breadCrumb'],
        '/cities': ['cities','breadCrumb'],

    },
    localeTitle: {
        'cs-CZ': 'Česky',
        'en-GB': 'English',
    },
};
