import {
  AvailableIntervalRow,
  AvailableSlot,
  CalendarBlock,
  CalendarIntervalOption,
  CheaperReservationPrice,
  ReservationData,
  ReservationFilterOptions,
  ReservationInfo, SeasonTicketRow,
  ShowPlaceModalData, TicketRow,
} from '../models';
import {CalendarAction} from '../actions/actionTypes';
import {Page} from "../../common/models";

export interface State {
  availableSLots: AvailableSlot[] | null;
  cheaperPrice: CheaperReservationPrice | null,
  filter: ReservationFilterOptions,
  reservationData: ReservationData,
  reservationInfo: ReservationInfo | null,
  calendar: {
    openHour: CalendarBlock[] | undefined,
    showNewReservation: boolean,
    showTicketModal: boolean,
    showBasket: boolean,
    showPlaceDetail: ShowPlaceModalData,
    interval: CalendarIntervalOption | null,
    validation: {
      result: {
        data: AvailableSlot | undefined,
        error: any[] | undefined
      }
    }
  }
  tickets: {
    data: Page<TicketRow> | undefined,
    intervals: AvailableIntervalRow[] | undefined,
    seasons: Page<SeasonTicketRow> | undefined,
  }
}

export const initialState: State = {
  availableSLots: null,
  cheaperPrice: null,
  filter: {
    length: 60,
  },
  reservationData: {
    registerCustomer: false,
    amount: 1,
    length: 60,
  },
  reservationInfo: null,
  calendar: {
    openHour: undefined,
    showNewReservation: false,
    showTicketModal: false,
    showPlaceDetail: {show: false, placeId: null},
    showBasket: false,
    interval: null,
    validation: {
      result: {
        data: undefined,
        error: undefined
      }
    },
  },
  tickets: {
    data: undefined,
    intervals: undefined,
    seasons: undefined
  },
};

export default function reducer(state: State = initialState, action: CalendarAction): State {
  switch (action.type) {
    case 'RESET_VALIDATE_SLOT_AVAILABILITY_ACTION': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          validation: {
            ...state.calendar.validation,
            result: {
              data: undefined,
              error: undefined
            }
          },
        },
      };
    }
    case 'VALIDATE_SLOT_AVAILABILITY_ACTION_FINISH': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          validation: {
            ...state.calendar.validation,
            result: {
              data: action.result,
              error: action.error
            }
          },
        },
      };
    }
    case 'SHOW_BASKET_ACTION': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          showBasket: action.status,
        },
      };
    }
    case 'SHOW_PLACE_DETAIL_ACTION': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          showPlaceDetail: action.status,
        },
      };
    }
    case 'CALENDAR_OPEN_TICKET_MODAL': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          interval: action.interval,
          showTicketModal: action.status,
        },
      };
    }
    case 'CALENDAR_OPEN_NEW_RESERVATION_ACTION': {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          interval: action.interval,
          showNewReservation: action.status,
        },
      };
    }
    case 'CALENDAR_GET_OPEN_HOURS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        calendar: {
          ...state.calendar,
          openHour: action.result,
        },
      };
    }
    case 'GET_AVAILABLE_SLOTS_ACTION_FINISH': {
      return {
        ...state,
        availableSLots: action.result,
      };
    }
    case 'GET_CHEAPER_PRICE_FOR_RESERVATION_ACTION_FINISH': {
      return {
        ...state,
        cheaperPrice: action.result,
      };
    }
    case 'SET_RESERVATION_FILTER_OPTION_ACTION': {
      return {
        ...state,
        filter: action.data,
      };
    }
    case 'SET_RESERVATION_FORM_DATA': {
      return {
        ...state,
        reservationData: action.data,
      };
    }
    case 'GET_RESERVATION_INFO_ACTION_FINISH': {
      return {
        ...state,
        reservationInfo: action.result,
      };
    }
    case 'GET_SEASON_TICKETS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          seasons: action.result,
        },
      };
    }
    case 'RESET_TICKET_AVAILABLE_INTERVALS_ACTION': {
      return {
        ...state,
        tickets: {
          ...state.tickets,
          intervals: undefined,
        },
      };
    }
    case 'GET_TICKET_AVAILABLE_INTERVALS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          intervals: action.result,
        },
      };
    }
    case 'GET_TICKETS_ACTION_FINISH': {
      if (!action.result) {
        return {...state};
      }

      return {
        ...state,
        tickets: {
          ...state.tickets,
          data: action.result,
        },
      };
    }
    default:
      return state;
  }
}
