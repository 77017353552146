export enum SeoResourceType {
    SPORTGROUND = 'SPORTGROUND',
    SPORTGROUND_CATEGORY = 'SPORTGROUND_CATEGORY',
    CATEGORY = 'CATEGORY',
    ARTICLE = 'ARTICLE',
    MICRO = 'MICRO'
}

export enum SeoAttributeType {
    TITLE = 'TITLE',
    DESCRIPTION = 'DESCRIPTION',
    IMAGE = 'IMAGE',
    ROBOTS = 'ROBOTS'
}

export enum BasketItemType {
  TICKET = 'TICKET',
  TIME_TICKET = 'TIME_TICKET',
  SEASON_TICKET = 'SEASON_TICKET'
}


export enum OrderItemType {
  TICKET = 'TICKET',
  TIME_TICKET = 'TIME_TICKET',
  SEASON_TICKET = 'SEASON_TICKET'
}

export enum SportgroundPlaceContentType {
  SHORT_DESCRIPTION = 'SHORT_DESCRIPTION',
  LONG_DESCRIPTION = 'LONG_DESCRIPTION',
}
