import {
  AvailableSlot,
  CheaperReservationPrice,
  CreateReservationResult,
  PaymentSource,
  ReservationData,
  ReservationFilterOptions,
  ReservationInfo
} from "../models";
import {ReservationAction} from "../actions/actionTypes";

export interface State {
  availableSLots: AvailableSlot[] | null;
  cheaperPrice: CheaperReservationPrice | null,
  filter: ReservationFilterOptions,
  reservationData: ReservationData,
  createReservationResult: CreateReservationResult,
  reservationInfo: ReservationInfo | null
}

export const initialState: State = {
  availableSLots: null,
  cheaperPrice: null,
  filter: {
    length: 60,
    parameters: []
  },
  reservationData: {
    registerCustomer: false,
    amount: 1,
    source: PaymentSource.SPORTYBE
  },
  createReservationResult: {
    error: false,
    messages: []
  },
  reservationInfo: null
};

export default function reducer(state: State = initialState, action: ReservationAction): State {
  switch (action.type) {
    case 'GET_AVAILABLE_SLOTS_ACTION_FINISH': {
      return {
        ...state,
        availableSLots: action.result,
      };
    }
    case 'GET_CHEAPER_PRICE_FOR_RESERVATION_ACTION_FINISH': {
      return {
        ...state,
        cheaperPrice: action.result,
      };
    }
    case 'SET_RESERVATION_FILTER_OPTION_ACTION': {
      return {
        ...state,
        filter: action.data,
      };
    }
    case 'SET_RESERVATION_RESULT_ACTION': {
      return {
        ...state,
        createReservationResult: action.data,
      };
    }
    case 'SET_RESERVATION_FORM_DATA': {
      return {
        ...state,
        reservationData: action.data,
      };
    }
    case 'GET_RESERVATION_INFO_ACTION_FINISH': {
      return {
        ...state,
        reservationInfo: action.result,
      };
    }
    default:
      return state;
  }
}
