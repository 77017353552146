import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { ExtraArgument } from 'src/types/redux';
import ApiClient from '../lib/api/src/api';
import globalEventsMiddleware from './globalEventsMiddleware';
import reducer, { initialState as GLOBAL_INITIAL_STATE } from './reducer';

const DEV_TOOLS_TRACE_LIMIT = 25;

export const initStore = (initialState = GLOBAL_INITIAL_STATE, context: any) => {
  const { req = null, res } = context;

  return createStore(
    reducer,
    initialState,
    composeWithDevTools({ trace: true, traceLimit: DEV_TOOLS_TRACE_LIMIT })(
      applyMiddleware(
        thunk.withExtraArgument<ExtraArgument>({
          api: new ApiClient(req, res),
          context,
        }),
        globalEventsMiddleware
      )
    )
  );
};
