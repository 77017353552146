
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import withRedux, {ReduxWrapperAppProps} from 'next-redux-wrapper';
import {resetAppMetaData, resolveSeoUrl, setAppMetaData, setCurrentPageType} from 'src/modules/app/actions';
import NextNProgress from 'nextjs-progressbar';
import {useMemo, useState} from 'react';
import {Provider} from 'react-redux';
import HeadMetaData from '../modules/app/components/headMetaData';
import {addUrlFunction, loadRoutes} from '../routing/utils';
import {RoutesProvider} from '../routing/utils/routesContext';
import {ApplicationContext, ApplicationContextType} from 'src/common/applicationWrapper/applicationContext';
import lzwcompress from 'lzwcompress';
import {initStore} from '../storage/store';
import {RoutesEnum} from '../routing/types';
import {NextAppContext, NextComponentProps, NextPageComponent, State} from '../types';
import {DehydratedState} from 'react-query/types/hydration';
import {parseCookie} from '../utils/cookies';
import {setCookies} from '../modules/app/actions/shared';
import App, {AppContext} from 'next/app';
import {queryParamToString} from '../storage/query';
import {NextComponentType} from 'next';
import {ReactQueryProvider} from '../common/applicationWrapper/reactQueryProvider';
import {CookiesProvider} from 'react-cookie';
import GlobalListeners from '../modules/ui/globalListeners';
import NotificationCenter from '../modules/ui/components/notificationCenter';
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import DevBanner from "../modules/common/components/devBanner";
import ThemeProvider from "@ds/components/themeProvider";
import {Theme} from '@ds/design-tokens/src/type';
import {getTheme} from "../utils/theme";
import GlobalStyle from "../styles/global/globalStyle";
import {SeoResourceType} from "../modules/app/models/enums";
import {ResolveSeoUrlActionFinish} from "../modules/app/actions/actionTypes";
import {HttpStatus} from "../types/enums/network";
import {isServer} from "../utils";
import {prefetchQueries} from "../common/utils/reactQuery";
import {getSportground, getSportgroundQueryKey} from "../modules/catalog/queries/useSportgroundQuery";

interface InitialProps {
  theme?: Theme;
  routes?: RoutesEnum;
  applicationContext?: ApplicationContextType['value'];
  pageProps: NextComponentProps;
}

interface AppProps extends Omit<ReduxWrapperAppProps<State, any>, 'pageProps'>, InitialProps {
}

const Application: NextComponentType<NextAppContext, InitialProps, AppProps> = ({
                                                                                  Component: PropsComponent,
                                                                                  store,
                                                                                  pageProps,
                                                                                  ...rest
                                                                                }) => {

  const [theme] = useState(rest.theme || ({} as Theme));
  const [routes] = useState(addUrlFunction(rest.applicationContext, rest.routes));

  const [applicationContext, setApplicationContext] = useState(rest.applicationContext);

  const applicationContextValue = useMemo(
    () => ({value: applicationContext, setValue: setApplicationContext}),
    [applicationContext, setApplicationContext]
  );
  const Component = PropsComponent as unknown as NextPageComponent;
  const ComponentLayout = Component.settings.layout;
  let layoutProps = Component.settings.layoutProps || {};

  if (applicationContext?.resourceId) {
    layoutProps = {
      ...layoutProps,
      isIndex: false
    }
  }

  //Google analytics - todo better solution
  ReactGA.initialize('G-44TNL52EC1');

  return (
    <ApplicationContext.Provider value={applicationContextValue}>
      <Provider store={store}>
        <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
          <ThemeProvider theme={theme}>
            <RoutesProvider value={routes}>
              <GlobalListeners/>
              <GlobalStyle/>
              <HeadMetaData/>
              <NextNProgress
                color="#79D331"
                startPosition={0.3}
                stopDelayMs={200}
                height={3}
                showOnShallow={true}
                options={{showSpinner: false}}
              />
              <NotificationCenter/>
              <CookiesProvider>
                {/*  <CookieConsent*/}
                {/*    location="bottom"*/}
                {/*    visible={consentDisablePages.includes(rest.router.pathname) ? 'hidden' : 'byCookieValue'}*/}
                {/*    buttonText="Souhlasím"*/}
                {/*    declineButtonText={"Nesouhlasím"}*/}
                {/*    cookieName="consentCookie"*/}
                {/*    enableDeclineButton*/}
                {/*    buttonStyle={{backgroundColor: "#79D231", borderRadius: "4px", color: "white"}}*/}
                {/*    declineButtonStyle={{*/}
                {/*      backgroundColor: "#E4E4E4",*/}
                {/*      borderRadius: "4px",*/}
                {/*      color: "#000"*/}
                {/*    }}*/}
                {/*    expires={150}*/}
                {/*  >*/}
                {/*    Tento web používá soubory cookie ke zlepšení uživatelského zážitku.*/}
                {/*  </CookieConsent>*/}
                <ComponentLayout {...layoutProps}>
                  <Component {...pageProps} />
                </ComponentLayout>
              </CookiesProvider>
            </RoutesProvider>
          </ThemeProvider>
        </ReactQueryProvider>
      </Provider>
    </ApplicationContext.Provider>
  );
};

Application.getInitialProps = async (appContext) => {
  let initialProps: InitialProps = {pageProps: {}};
  const query = appContext.router.query;
  const Component = appContext.Component as unknown as NextPageComponent;
  const {ctx: pageContext} = appContext;
  const {dispatch} = pageContext.store;
  let queryClient: DehydratedState | undefined;

  const {req, res} = pageContext;

  const [app] = queryParamToString(query.app);
  let resourceId: string | null = null;

  if (req) {
    const configuration = req.configuration;
    const [theme, routes] = await Promise.all([getTheme(), loadRoutes()]);
    initialProps = {
      ...initialProps,
      theme,
      routes,
      applicationContext: {
        configuration,
        deviceInfo: {...req.useragent, isApp: app === 'true'},
        versions: req.versions,
        host: req.headers.host as string,
        secure: !!req.headers["x-forwarded-proto"]
      },
    };

    const cookies = parseCookie(req.headers.cookie || '');

    dispatch(setCookies(cookies));

    const splitted: string[] | undefined = req?.headers.host?.split('.');
    const sportgroundDomain = splitted?.length == 3 ? splitted[0] : null;

    // load sportgroud by dynamic subdomain *.sportybe.com
    if (sportgroundDomain) {
      const {result} = await dispatch(resolveSeoUrl({
        value: sportgroundDomain as string,
        resourceType: SeoResourceType.MICRO,
      })) as ResolveSeoUrlActionFinish;

      if (result == null) {
        // TODO implement redirect
        // res?.writeHead(HttpStatus.TemporaryRedirect, {Location: '/error/404'}).end();
      }

      if (result?.redirectCode != null) {
        // TODO implement redirect
        // res?.writeHead(result?.redirectCode, {Location: `/${translateSeoUrlType(result.redirectType as SeoResourceType)}/${result.redirectPath}`}).end()
      }
      if (result?.resourceId) {
        resourceId = result?.resourceId;
        initialProps = {
          ...initialProps,
          applicationContext: {
            configuration,
            deviceInfo: {...req.useragent, isApp: app === 'true'},
            versions: req.versions,
            resourceId: result?.resourceId,
            host: req.headers.host as string,
            secure: !!req.headers["x-forwarded-proto"]
          },
        };
      }
    }

  }

  dispatch(resetAppMetaData());

  const {pageProps} = (await App.getInitialProps(appContext as AppContext)) as { pageProps: NextComponentProps };

  let {image, title, desc, hasTitleAppend, robots} = Component.settings;

  if (pageProps) {
    title = pageProps.title || title;
    image = pageProps.image || image;
    desc = pageProps.desc || desc;
    robots = pageProps.robots || robots;

    dispatch(setAppMetaData(title, image, desc, robots));

  }

  dispatch(setCurrentPageType(Component.settings.pageType || null));

  return {
    ...initialProps,
    pageProps: {
      ...pageProps,
      dehydratedState: {
        mutations: [...(queryClient?.mutations || []), ...(pageProps.dehydratedState?.mutations || [])],
        queries: [...(queryClient?.queries || []), ...(pageProps.dehydratedState?.queries || [])],
        resourceId
      },
    },
  };
};

const __Page_Next_Translate__ = withRedux(initStore, {
  serializeState: lzwcompress.pack,
  deserializeState: lzwcompress.unpack,
})(Application);


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  