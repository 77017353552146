import {Page} from '../../common/models';
import {RentalItemRow} from "../models";
import {RentalAction} from "../actions/actionTypes";

export interface State {
    rentalItems: Page<RentalItemRow> | undefined
}

export const initialState: State = {

    rentalItems: undefined,
};

export default function reducer(state: State = initialState, action: RentalAction): State {
    switch (action.type) {

        case 'GET_RENTAL_ITEMS_ACTION_FINISH': {
            if (!action.result) {
                return {...state};
            }

            return {
                ...state,
                rentalItems: action.result,
            };
        }

        default:
            return state;
    }
}
