import { css } from 'styled-components';

const helpersButton = css`
  .buttonReset {
    height: auto;
    padding: 0;
    color: initial;
    font-weight: normal;
    line-height: 1;
    background: transparent;
    border-radius: 0;

    &:hover {
      background: transparent;
    }
  }
`;

export default helpersButton;
