import { createContext } from 'react';
import { Configuration } from '../../configuration/model';
import { DeviceInfo } from '../../types';

interface ContextValue {
  configuration: Configuration;
  deviceInfo: DeviceInfo;
  versions?: {
    client: string;
    api: string | null;
  };
  resourceId?: string |  null,
  host: string,
  secure: boolean
}
export interface ApplicationContextType {
  setValue: React.Dispatch<React.SetStateAction<ContextValue | undefined>>;
  value?: ContextValue;
}

export const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);
