import {HomepageAction} from '../actions/actionTypes';
import {Category, Sportground} from '../../catalog/models';
import {AggregateCity} from '../../cities/models';
import {SearchFormData} from '../models';

export interface State {
  category: Category[],
  form: SearchFormData
  aroundSportgrounds: Sportground[];
  topCities: AggregateCity[];
}

export const initialState: State = {
  category: [],
  form: {},
  aroundSportgrounds: [],
  topCities: [],
};

export default function reducer(state: State = initialState, action: HomepageAction): State {
  switch (action.type) {
    case 'LOAD_CATEGORY_FOR_HOMEPAGE_ACTION_FINISH': {
      return {
        ...state,
        category: action.result?.content,
      };
    }
    case 'GET_AROUND_SPORTGROUND_ACTION_FINISH': {
      return {
        ...state,
        aroundSportgrounds: action.result?.content
      };
    }
    case 'GET_TOP_CITIES_ACTION_FINISH': {
      return {
        ...state,
        topCities: action.result?.content,
      }
    }
    case 'SET_SEARCH_FORM_DATA': {
      return {
        ...state,
        form: action.data
      }
    }
    default:
      return state;
  }
}
