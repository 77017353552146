import { css } from 'styled-components';

const headings = css(
  ({ theme }) => css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      color: ${theme.colorTextPrimary};
    }

    h1 {
      font-size: 32px;
      font-weight: normal;
      margin-bottom: 24px;
      line-height: 42px;

      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    h2 {
      font-weight: normal;
      font-size: 18px;
      margin-bottom: 12px;
    }
  `
);

export default headings;
