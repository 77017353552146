import {getConfig} from 'src/utils/next';
import {CustomerFavoriteType} from "../../modules/catalog/models";

export type ApiEndpointName = keyof typeof API_CONFIG;

const {
  frontendApiService: {path: frontendApiService},
  securityService: {path: securityService},
} = getConfig().services;

const API_CONFIG = {
  ACTIVATE_ACCOUNT: (accountId: string) => `${frontendApiService}/account/${accountId}/activate`,
  GET_CLOSEST_LOCATION: () => `${frontendApiService}/location/coordinates`,
  SUGGEST_LOCATION_ACTION: () => `${frontendApiService}/location/suggest`,
  CATEGORY_CACHE_FINDER: () => `${frontendApiService}/catalog/category/cache/finder`,
  SPORTGROUND_CACHE_FINDER: () => `${frontendApiService}/catalog/sportground/cache/finder`,
  AGGREGATE_SPORTGROUND_CITY: () => `${frontendApiService}/aggregation/aggregate/sportground/city`,
  AGGREGATE_SPORTGROUND_CITY_PART: () => `${frontendApiService}/aggregation/aggregate/sportground/city?aggregateCityPart=true`,
  GET_FAVORITE_CATEGORIES: (accountId: string) => `${frontendApiService}/account/favorite/categories/${accountId}`,
  GET_FAVORITE_LOCATIONS: (accountId: string) => `${frontendApiService}/account/favorite/locations/${accountId}`,
  GET_FAVORITE_SPORTGROUNDS: (accountId: string) => `${frontendApiService}/account/favorite/sportgrounds/${accountId}`,
  GET_ACCOUNT_ABOUT: (accountId: string) => `${frontendApiService}/account/about/${accountId}`,
  FACEBOOK_LOGIN_INIT: () => `${securityService}/oauth/facebook/init`,
  FACEBOOK_LOGIN_CALLBACK: (code: string) => `${securityService}/oauth/facebook/callback?code=${code}`,
  APPLE_LOGIN_CALLBACK: () => `${securityService}/oauth/apple/callback`,
  GOOGLE_LOGIN_INIT: () => `${securityService}/oauth/google/init`,
  GOOGLE_LOGIN_CALLBACK: () => `${securityService}/oauth/google/callback`,
  AUTHENTICATE: () => `${securityService}/security/authenticate`,
  SEARCH_AVAILABLE_SPORTGROUND_FOR_RESERVATION: () => `${frontendApiService}/search`,
  SEARCH_CATALOG_SPORTGROUNS: () => `${frontendApiService}/search/catalog`,
  GET_SEARCH_INFORMATION: () => `${frontendApiService}/search/information`,
  GET_SEARCH_PARAMETERS: () => `${frontendApiService}/search/parameters`,
  TOGGLE_FAVORITE: (accountId: string, resourceType: CustomerFavoriteType, resourceId: string) => `${frontendApiService}/account/favorite/${accountId}/${resourceType}/resource/${resourceId}`,
  GET_SPORTGROUND_DETAIL: (sportgroundId: string) => `${frontendApiService}/catalog/sportground/${sportgroundId}`,
  GET_SPORTGROUND_DETAIL_DATA: (sportgroundId: string) => `${frontendApiService}/catalog/sportground/${sportgroundId}`,
  GET_CATEGORY_DETAIL: (categoryId: string) => `${frontendApiService}/catalog/category/${categoryId}`,
  SPORTGROUND_FINDER: () => `${frontendApiService}/catalog/sportground/finder`,
  GET_CATEGORY_CODEBOOK: () => `${frontendApiService}/catalog/category/codebook`,
  GET_RESERVATION_OPTIONS: (sportgroundId: string) => `${frontendApiService}/reservation/${sportgroundId}/options`,
  GET_AVAILABLE_SLOTS: (sportgroundId: string) => `${frontendApiService}/reservation/${sportgroundId}/available-slots`,
  GET_CHEAPER_PRICE_FOR_RESERVATION: (sportgroundId: string) => `${frontendApiService}/reservation/${sportgroundId}/cheaper-price`,
  CREATE_RESERVATION: (sportgroundId: string) => `${frontendApiService}/reservation/${sportgroundId}/create`,
  VALIDATE_AVAILABLE_SLOT: (sportgroundId: string) => `${frontendApiService}/reservation/${sportgroundId}/available-slot/validate`,
  REGISTER_CUSTOMER: () => `${frontendApiService}/customer/register`,
  CHANGE_PASSWORD: (userId: string) => `${frontendApiService}/customer/change-password/${userId}`,
  GET_CUSTOMER_RESERVATIONS: (accountId: string) => `${frontendApiService}/account/${accountId}/reservations/finder`,
  GET_ARTICLE_DETAIL: (articleId: string) => `${frontendApiService}/article/${articleId}`,
  ARTICLE_FINDER: () => `${frontendApiService}/article/cache/finder`,
  LOAD_VIRTUAL_WALLET: (accountId: string) => `${frontendApiService}/account/${accountId}/virtual-wallet/load`,
  FILTER_VIRTUAL_WALLET_TRANSACTION: (accountId: string) => `${frontendApiService}/account/${accountId}/virtual-wallet/filter`,
  ARTICLE_CODE_BOOK: () => `${frontendApiService}/article/codebook`,
  SPORTGROUND_REVIEW: (sportgroundId: string) => `${frontendApiService}/catalog/sportground/${sportgroundId}/review`,
  ACCOUNT_RESERVATION: (accountId: string, reservationId: string) => `${frontendApiService}/account/${accountId}/reservations/${reservationId}`,
  ACCOUNT_BASE: (accountId: string) => `${frontendApiService}/account/${accountId}/base-settings`,
  GET_RESERVATION_BY_ID: (reservationId: string) => `${frontendApiService}/reservation/${reservationId}`,
  PAYMENTS: () => `${frontendApiService}/callback/payments`,
  RESEND_ACTIVATE_ACCOUNT: (accountId: string) => `${frontendApiService}/account/${accountId}/resend`,
  RESOLVE_SEO_URL: () => `${frontendApiService}/seo/resolve`,
  PROCESS_BASKET: () => `${frontendApiService}/basket/process`,
  GET_CUSTOMER_CONDITIONS: (accountId: string) => `${frontendApiService}/account/${accountId}/conditions/finder`,
  CUSTOMER_CONDITIONS: (accountId: string) => `${frontendApiService}/account/${accountId}/conditions`,
  CONDITION_ID: (conditionId: string) => `${frontendApiService}/sportground-condition/${conditionId}`,
  PROCESS_ORDER: (orderId: string) => `${frontendApiService}/callback/order/${orderId}`,
  CHANGE_BASKET_ITEM_AMOUNT: (sportgroundId: string) => `${frontendApiService}/basket/${sportgroundId}/change-amount`,
  GET_VIRTUAL_WALLET: (sportgroundId: string, accountId: string) => `${frontendApiService}/${sportgroundId}/account/${accountId}/virtual-wallet`,
  GET_BASKET: (sportgroundId: string) => `${frontendApiService}/basket/${sportgroundId}`,
  ORDER_BY_ID: (orderId: string) => `${frontendApiService}/order/${orderId}`,
  GET_CUSTOMER_TICKETS: (accountId: string) => `${frontendApiService}/account/${accountId}/tickets/finder`,
  CUSTOMER_TICKET_DETAIL: (accountId: string, customerTicketId: string) => `${frontendApiService}/account/${accountId}/tickets/${customerTicketId}`,
  GET_CUSTOMER_SEASON_TICKETS: (accountId: string) => `${frontendApiService}/account/${accountId}/season-tickets/finder`,
  CUSTOMER_SEASON_TICKET_DETAIL: (accountId: string, customerSeasonTicketId: string) => `${frontendApiService}/account/${accountId}/season-tickets/${customerSeasonTicketId}`,
  FORGOTTEN_PASSWORD: () => `${frontendApiService}/customer/forgotten-password`,
  GET_SPORTGROUND_PLACE_DETAIL_URL: (placeId: string) => `${frontendApiService}/catalog/sportground/place/${placeId}`,
  ACTIVATE_NEW_PASSWORD: (accountId: string) => `${frontendApiService}/account/${accountId}/new-password`,
  CALCULATE_STORNO_FEE: (accountId: string, reservationId: string) => `${frontendApiService}/account/${accountId}/reservations/${reservationId}/calculate-storno`,
  VALIDATE_SLOT_AVAILABILITY: (sportgroundId: string) => `${frontendApiService}/reservation/slot/${sportgroundId}/validate-availability`,
  CONTACT_US: () => `${frontendApiService}/contact/us`,
  GET_CALENDAR_OPEN_HOUR: (sportgroundId: string) => `${frontendApiService}/reservation/calendar/available/${sportgroundId}`,
  GET_TICKET_AVAILABLE_INTERVALS: (ticketId: string) => `${frontendApiService}/ticket/${ticketId}/available-intervals`,
  GET_TICKETS: () => `${frontendApiService}/ticket/filter`,
  GET_SEASON_TICKETS: () => `${frontendApiService}/season-ticket/filter`,
  ADD_ITEM_TO_BASKET: (sportgroundId: string) => `${frontendApiService}/basket/${sportgroundId}/add-item`,
  GET_RENTAL_ITEMS: () => `${frontendApiService}/rental/item/filter`,
  ADD_RENTAL_RESERVATION: () => `${frontendApiService}/rental/add-to-reservation`,
};

export default API_CONFIG;
