import API_CONFIG, {ApiEndpointName} from '../config';
import {UrlParams} from '../types';

import {setUrlParams} from '../../../routing/utils/setUrlParams';
import {isServer} from '../../../utils';
import {getConfig} from '../../../utils/next';
import {GenericResponse} from "../../../modules/common/models";
import {HttpStatus} from "../../../types/enums/network";

export function formatUrl(path: string): string {
    const adjustedPath = path[0] != '/' ? `/${path}` : path;
    const {services} = getConfig();
    if (isServer()) {
        const serviceInPath = Object.values<{ path: string; host: string }>(services).find(
            (service) => path.indexOf(service.path) != -1
        );

        let uri = (getConfig().apiURL || (serviceInPath && serviceInPath.host)) + adjustedPath;
        if (uri.indexOf('http') === -1) {
            uri = `http://${uri}`;
        }
        return uri;
    }

    return adjustedPath;
}

export function getPath(key: ApiEndpointName, urlParts?: Array<string | number>, urlParams: UrlParams = {}): string {
    const encodedParts: string[] = (urlParts || []).map((item: string | number) => encodeURIComponent(item.toString()));
    // @ts-ignore
    const url: Nullable<string> = API_CONFIG[key] ? API_CONFIG[key](...encodedParts) : null;

    if (!url) {
        throw new Error(`No url definned in apiConfig for key='${key}'`);
    }

    return formatUrl(setUrlParams(url, urlParams));
}


export const returnOrThrowApiResponse = <T>(respData: GenericResponse<T>): GenericResponse<T> => {
    if (!respData.success) {
        throw respData;
    }
    return respData;
};
