import { css } from 'styled-components';

const rcCollapse = css`
  .rc-collapse-anim-active {
    transition: height 0.2s ease-out;
  }

  .rc-collapse .rc-collapse-item .rc-collapse-header {
    cursor: pointer;
    position: relative;
  }

  .rc-collapse .rc-collapse-item-disabled .rc-collapse-header {
    cursor: not-allowed;
  }

  .rc-collapse-content {
    overflow: hidden;
    padding: 0;
  }

  .rc-collapse-content-inactive {
    height: 0;
    transition: height 1s ease;
    overflow: hidden;
  }

  .rc-collapse-content-inactive .rc-collapse-content-box {
    display: none;
  }

  .rc-collapse-item.animation .rc-collapse-content-box {
    animation: animationHeight 0.5s ease forwards;
  }

  @keyframes animationHeight {
    0% {
      height: 400px;
    }

    100% {
      height: 0;
    }
  }
`;

export default rcCollapse;
