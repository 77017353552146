import {Page} from '../../common/models';
import {ArticleCodeBook, ArticleSearchPayload, ArticleSearchResultRow} from '../models';
import {ArticleAction} from '../actions/actionTypes';

export interface State {
    search: {
        codebook: ArticleCodeBook | null
        articles: Page<ArticleSearchResultRow> | null
        articlesNews: Page<ArticleSearchResultRow> | null
        articlesSearchPayload: ArticleSearchPayload
    }
    detail: {
        articles: Page<ArticleSearchResultRow> | null
    }
}

export const initialState: State = {
    search: {
        articles: null,
        codebook: null,
        articlesNews: null,
        articlesSearchPayload: {}
    },
    detail: {
        articles: null
    }
};

export default function reducer(state: State = initialState, action: ArticleAction): State {
    switch (action.type) {
        case 'FIND_ARTICLES_FOR_DETAIL_ACTION_FINISH': {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    articles: action.result
                }
            };
        }
        case 'GET_ARTICLE_CODEBOOK_ACTION_FINISH': {
            return {
                ...state,
                search: {
                    ...state.search,
                    codebook: action.result
                }
            };
        }
        case 'FIND_ARTICLES_FOR_SEARCH_ACTION_FINISH': {
            return {
                ...state,
                search: {
                    ...state.search,
                    articles: action.result
                }
            };
        }
        case 'FIND_ARTICLE_NEWS_FOR_SEARCH_ACTION_FINISH': {
            return {
                ...state,
                search: {
                    ...state.search,
                    articlesNews: action.result
                }
            }
                ;
        }
        case 'SET_ARTICLE_SEARCH_PAYLOAD_ACTION': {
            return {
                ...state,
                search: {
                    ...state.search,
                    articlesSearchPayload: action.data
                }
            }
                ;
        }
        default:
            return state;
    }
}