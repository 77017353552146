import { css } from 'styled-components';

const imagePlaceholder = css(
  ({ theme }) => css`
    .imagePlaceholder {
      width: 100%;
      height: 100%;
      background: ${theme.backgroundColorSecondary};
    }
  `
);

export default imagePlaceholder;
