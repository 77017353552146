import {
  Category,
  CategoryFinderPayload,
  FavoriteLocation,
  Location,
  OpenStatusData,
  SearchFilter,
  SearchInformationResponse,
  SearchParametersGroup,
  Sportground,
  SportgroundReviewRow
} from '../models';
import {CatalogAction} from '../actions/actionTypes';
import {Page} from '../../common/models';
import {ArticleSearchResultRow} from '../../article/models';

export interface State {
  location: {
    location?: Location | null,
    suggest: Location[];
    favorite: FavoriteLocation[],
  };
  category: {
    detail: {
      articles: Page<ArticleSearchResultRow> | null
    }
    suggest: Category[],
    favorite: Category[],
    catalog: {
      categories: Page<Category> | null,
      filter: CategoryFinderPayload
    }
  },
  sportground: {
    search: {
      available: Page<Sportground> | null
      catalog: Page<Sportground> | null
      information: SearchInformationResponse | null,
      parameters: Map<string, SearchParametersGroup> | null,
      filter: SearchFilter
    },
    detail: {
      sportgrounds: Page<Sportground> | null
      openStatus: OpenStatusData | null
      openHourList: { [key: string]: string[] }
      reviews: Page<SportgroundReviewRow> | null
    }
  }
}

export const initialState: State = {
  location: {
    suggest: [],
    favorite: []
  },
  category: {
    detail: {
      articles: null
    },
    suggest: [],
    favorite: [],
    catalog: {
      categories: null,
      filter: {
        findFavorite: false,
        types: []
      }
    },
  },
  sportground: {
    search: {
      available: null,
      catalog: null,
      information: null,
      parameters: new Map(),
      filter: {
        length: 60,
        parameters: [],
        placeParameters: []
      }
    },
    detail: {
      sportgrounds: null,
      openStatus: null,
      openHourList: {},
      reviews: null,
    }
  }
};

export default function reducer(state: State = initialState, action: CatalogAction): State {
  switch (action.type) {
    case 'GET_SPORTGROUND_FOR_CATEGORY_ACTION_FINISH': {
      return {
        ...state,
        sportground: {
          ...state.sportground,
          detail: {
            ...state.sportground.detail,
            sportgrounds: action.result
          }
        },
      };
    }
    case 'GET_CLOSEST_LOCATION_ACTION_FINISH': {
      return {
        ...state,
        location: {
          ...state.location,
          location: action.result
        },
      };
    }
    case 'SUGGEST_LOCATION_ACTION_FINISH': {
      return {
        ...state,
        location: {
          ...state.location,
          suggest: action.result.content
        },
      };
    }
    case 'LOAD_FAVORITE_LOCATIONS_ACTION_FINISH': {
      return {
        ...state,
        location: {
          ...state.location,
          favorite: action.result.content
        },
      };
    }
    case 'SUGGEST_CATEGORY_ACTION_FINISH': {
      return {
        ...state,
        category: {
          ...state.category,
          suggest: action.result.content
        }
      };
    }
    case 'RESET_SUGGESTED_CATEGORY': {
      return {
        ...state,
        category: {
          ...state.category,
          suggest: []
        },
      };
    }
    case 'RESET_SUGGESTED_LOCATION': {
      return {
        ...state,
        location: {
          ...state.location,
          suggest: []
        },
      };
    }
    case 'LOAD_FAVORITE_CATEGORIES_ACTION_FINISH': {
      return {
        ...state,
        category: {
          ...state.category,
          favorite: action.result.content
        },
      };
    }
    case 'SEARCH_AVAILABLE_SPORTGROUND_FOR_RESERVATION_ACTION_FINISH': {
      if (!action.result) {
        return {
          ...state
        }
      }

      return {
        ...state,
        sportground: {
          ...state.sportground,
          search: {
            ...state.sportground.search,
            available: action.result
          }
        },
      };
    }
    case 'SEARCH_CATALOG_SPORTGROUNDS_ACTION_FINISH': {
      if (!action.result) {
        return {
          ...state
        }
      }

      return {
        ...state,
        sportground: {
          ...state.sportground,
          search: {
            ...state.sportground.search,
            catalog: action.result
          }
        },
      };
    }
    case 'GET_SEARCH_INFORMATION_ACTION_FINISH': {
      if (!action.result) {
        return {
          ...state
        }
      }

      return {
        ...state,
        sportground: {
          ...state.sportground,
          search: {
            ...state.sportground.search,
            information: action.result
          }
        },
      };
    }
    case 'GET_SEARCH_PARAMETERS_ACTION_FINISH': {
      if (!action.result) {
        return {
          ...state
        }
      }

      return {
        ...state,
        sportground: {
          ...state.sportground,
          search: {
            ...state.sportground.search,
            parameters: action.result
          }
        },
      };
    }
    case 'SET_SEARCH_FILTER_DATA': {
      return {
        ...state,
        sportground: {
          ...state.sportground,
          search: {
            ...state.sportground.search,
            filter: action.data
          }
        },
      };
    }
    case 'GET_CATEGORY_CATALOG_ACTION_FINISH': {
      return {
        ...state,
        category: {
          ...state.category,
          catalog: {
            ...state.category.catalog,
            categories: action.result
          }
        },
      };
    }
    case 'SET_CATEGORY_CATALOG_FINDER_FILTER': {
      return {
        ...state,
        category: {
          ...state.category,
          catalog: {
            ...state.category.catalog,
            filter: action.data
          }
        },
      };
    }
    case 'SET_SPORTGROUND_DETAIL_OPEN_STATUS': {
      return {
        ...state,
        sportground: {
          ...state.sportground,
          detail: {
            ...state.sportground.detail,
            openStatus: action.data
          }
        },
      };
    }
    case 'SET_SPORTGROUND_DETAIL_OPEN_HOUR_LIST': {
      return {
        ...state,
        sportground: {
          ...state.sportground,
          detail: {
            ...state.sportground.detail,
            openHourList: action.data
          }
        },
      };
    }

    case 'FIND_ARTICLES_FOR_CATEGORY_ACTION_FINISH': {
      return {
        ...state,
        category: {
          ...state.category,
          detail: {
            ...state.sportground.detail,
            articles: action.result
          }
        },
      };
    }

    case 'GET_SPORTGROUND_REVIEWS_ACTION_FINISH': {
      return {
        ...state,
        sportground: {
          ...state.sportground,
          detail: {
            ...state.sportground.detail,
            reviews: action.result
          }
        },
      };
    }
    case 'RESET_SPORTGROUND_SEARCH': {
      return {
        ...state,
        sportground: {
          ...state.sportground,
          search: initialState.sportground.search
        },
      };
    }
    default:
      return state;
  }
}

