import React, { memo } from 'react';
import Notifications from 'react-notification-system-redux';
import { useSelector } from 'react-redux';
import { State } from 'src/types';

const NotificationCenter: React.FC = () => {
  const notifications = useSelector((state: State) => state.notifications);

  // first iteration, must be styled
  return <Notifications notifications={notifications} />;
};

export default memo(NotificationCenter);
