import { css } from 'styled-components';
import { sizes } from 'src/styles/mediaQueries';

const mobile = css`
  .mobile-show {
    display: none;
    @media (max-width: ${sizes.sDeviceUp}) {
      display: block;
    }
  }

  .mobile-hidden {
    @media (max-width: ${sizes.sDeviceUp}) {
      display: none !important;
    }
  }
`;

export default mobile;
