import { css } from 'styled-components';

const colors = css(
  ({ theme }) => css`
    .colorGreenPrimary {
      color: ${theme.colors.green[60]} !important;
    }

    .colorRedPrimary {
      color: ${theme.colors.red[60]} !important;
    }

    .colorYellowPrimary {
      color: ${theme.colors.yellow[60]} !important;
    }

    .colorPinkPrimary {
      color: ${theme.colors.pink[60]} !important;
    }

    .colorBluePrimary {
      color: ${theme.colors.blue[60]} !important;
    }

    .colorPrimary {
      color: ${theme.colors.grey[90]} !important;
    }

    .colorSecondary {
      color: ${theme.colors.grey[70]} !important;
    }

    .colorInverted {
      color: ${theme.colors.grey[0]} !important;
    }
  `
);

export default colors;
