import { css } from 'styled-components';

const inputComponent = css(
  ({ theme }) => css`
    .Input__hasError {
      input {
        border-color: ${theme.borderColorNegative};
      }
    }
  `
);

export default inputComponent;
